define('service-data-links',[
	'module',
	'underscore',
	'util',
	'service-data',
	'proto-decoder-book-link',
	'service-data-book',
	'service-config-book'
], function (module, _, Util, DataService, Serializer, BookSrvc, BookConfigSrvc) {

	return DataService.extend({}, {

		_name: module.id,

		_mapping: {},

		load: function (options) {
			this.init();

			if ( !BookSrvc.feature('links.enabled') )
			{
				var doc = new Serializer.BookLink.Document();
				this.set( doc );

				// No links, the promise is resolved as if the webservice returned no links
				this._promise.resolve();

				return this._promise;
			}

			var url = !this.isFallback ? BookSrvc.feature('links.url') : BookSrvc.feature('links.fallback');

			if (BookSrvc.feature('links.version') !== 2) {
				return this.request( _.extend({
					url: url,
					data: _.extend({}, BookConfigSrvc.get()), // Using endpoint from link version
				}, options, this.getCredentialOptions && this.getCredentialOptions()) );
			}

			var credentials = {xhrFields: {}};
			var format = {};
			var data = _.extend({}, BookConfigSrvc.get());

			console.warn('LinkData => Using version 2');

			if ( BookSrvc.feature('links.bin.enabled') )
			{
				this.hasFallback = false;

				data = {};

				url = BookSrvc.signUrl( BookSrvc.feature('links.bin.url') );
			}
			else
			{
				credentials = this.getCredentialOptions && this.getCredentialOptions();

				data.v		= 2;
				data.output	= 'protobuf';
			}

			credentials 			= _.extend({}, credentials || {});
			var xhrFields			= _.extend({}, credentials.xhrFields || {});
			xhrFields.responseType	= 'arraybuffer';
			credentials.xhrFields	= xhrFields;

			format = {
				accept: 'application/protobuf',
				dataType: 'protobuf', // Override 'json'
				converters: {
					'* protobuf': function(value) {
						return Serializer.BookLink.Document.decode( new Uint8Array(value) );
					}
				},
				success: this.onLoadSuccess
			};

			return this.request( _.extend({
				url: url,
				data: data,
			}, options, credentials, format) );
		},

		onError: function(xhr, textStatus, errorThrown)
		{	
			// Ignore fallback and log when requests are cancelled by navigation?
			if ( xhr.status == 0 ) {
				this._promise.reject(410, 'Loading cancelled', true);
				return;
			}

			DataService.onError.apply(this, arguments);
		},

		page: function(page){
			page = page - page % 2;
			var index = this._mapping[page];
			if ( _.isUndefined(index) ) return [];
			var doc = this.get() || {};
			var pages = doc.pages || [];
			var pageHolder = pages[index] || {};
			console.warn(pageHolder);
			return pageHolder.links || [];
		},

		set: function(data, isProto) {

			var doc;
			if ( !isProto ) {
				// Transform JSON to Proto
				var pages = _.map(data, function (value, key, list) {
					var links = _.map(value, function (element, index, list) {
					//   if (element.to) {
					// 	try {
					// 		element.to = decodeURIComponent(element.to);
					// 	} catch (err) {
					// 		console.error('Error decoding link: ' + element.to);
					// 	}
					//   }
					  var options = [];
					  if ( element.o ) {
					    for ( var k in element.o ) {
					      options.push({ key: k, value: element.o[k] });
					    }
					  }

					  return {
					    type: element.t,
					    to: element.to,
					    label: element.tx,
					    sx: element.sx,
					    sy: element.sy,
					    ex: element.ex,
					    ey: element.ey,
					    options: options
					  };
					});

					return {pageNumber: key, links: links};
				});

				doc = new Serializer.BookLink.Document({version: 1, pages: pages});
			} else {
				doc = data;
			}

			console.info('LinkData => Success:', doc);

			// Prepare page <-> index mapping
			this._mapping = _.reduce(doc.pages, function(dict, page, index) {
				var pageNumber = page.pageNumber;
				pageNumber = pageNumber - pageNumber % 2;
				dict[pageNumber] = index;
				return dict;
			}, {});

			return DataService.set.apply(this, [doc]);
		},

		onLoadSuccess: function(data, textStatus, xhr) {
			this.set( data, true );
			this._promise.resolve();
		}
	});

});
