define('service-data-toc',[
	'module',
	'underscore',
	'util',
	'service-data',
	'proto-decoder-book-toc',
	'service-data-book',
	'service-config-book'
], function (module, _, Util, APIService, Serializer, BookSrvc, BookConfigSrvc) {

	return APIService.extend({}, {

		_name: module.id,

		load: function (options) {
			this.init();

			if ( !BookSrvc.feature('toc.enabled') )
			{
				var doc = new Serializer.BookToc.Document();
				this.set( doc );

				// No links, the promise is resolved as if the webservice returned no links
				this._promise.resolve();

				return this._promise;
			}

			var url = !this.isFallback ? BookSrvc.feature('toc.url') : BookSrvc.feature('toc.fallback');

			if (BookSrvc.feature('toc.version') !== 2) {
				return this.request( _.extend({
					url: url,
					data: _.extend({}, BookConfigSrvc.get()), // Using endpoint from link version
				}, options, this.getCredentialOptions && this.getCredentialOptions()) );
			}

			var credentials = {xhrFields: {}};
			var format = {};
			var data = _.extend({}, BookConfigSrvc.get());

			console.warn('TocData => Using version 2');

			if ( BookSrvc.feature('toc.bin.enabled') )
			{
				this.hasFallback = false;

				data = {};

				url = BookSrvc.signUrl( BookSrvc.feature('toc.bin.url') );
			}
			else
			{
				credentials = this.getCredentialOptions && this.getCredentialOptions();

				data.v		= 2;
				data.output	= 'protobuf';
			}

			credentials 			= _.extend({}, credentials || {});
			var xhrFields			= _.extend({}, credentials.xhrFields || {});
			xhrFields.responseType	= 'arraybuffer';
			credentials.xhrFields	= xhrFields;

			format = {
				accept: 'application/protobuf',
				dataType: 'protobuf', // Override 'json'
				converters: {
					'* protobuf': function(value) {
						return Serializer.BookToc.Document.decode( new Uint8Array(value) );
					}
				},
				success: this.onLoadSuccess
			};

			return this.request( _.extend({
				url: url,
				data: data,
			}, options, credentials, format) );
		},

		total: function() {
			return this.get().length;
		},

		onLoadSuccess: function(data, textStatus, xhr) {
			console.info('TocData => Success:', data.items);
			this.set( data.items );
			this._promise.resolve();
		}
	});

});
