define('tracker-ga4',[
	'jquery',
	'underscore',
	'constants',
	'status',
	'util',
	'tracker',
	'service-data-book',
	'service-config-loader',
	'service-config-book',
	'cmp'
], function ($, _, $C, $S, Util, Tracker, BookSrvc, LoaderConfigSrvc, BookConfigSrvc, CMP) {
			
	if (!window.gtag)
	{
		window.dataLayer = window.dataLayer || [];
		window.gtag = function(){ window.dataLayer.push(arguments); };
		window.gtag('js', new Date());
	}

	return Tracker.extend({

		id: 'ga4',
		adBlockDetected: false,

		isTrackingEnabled: function() {
			return true;
		},

		getName: function() {
			return 'Tracker'
		},

		getCode: function() {
			return '';
		},

		getProperties: function() {
			return {};
		},

		getUserProperties: function() {
			return {};
		},

		getEventFilter: function() {
			return null;
		},

		log: function() {
			var args = Array.prototype.slice.call(arguments);
			args.unshift('Tracking [GA4] => [' + this.getName() + ']');
			console.log.apply(console, args);
		},

		warn: function() {
			var args = Array.prototype.slice.call(arguments);
			args.unshift('Tracking [GA4] => [' + this.getName() + ']');
			console.warn.apply(console, args);
		},

		load: function(){
			if ( !this.isTrackingEnabled() ) return;

			// Already loaded
			if ( this.IsLoaded ) return false;

			this.log('Starting...');

			this.loadGTAG();

			this.detectAdblock();

			// User properties
			this.setUserPropertiesSettings();

			// Config
			var options = {
				cookie_flags: 'SameSite=None;Secure',
				send_page_view: false,
				debug_mode: Calameo.defaults.google.analytics.debug
			};

			// Create the Calameo trackers
			this.log('Config:', options);
			window.gtag('config', this.getCode(), options);

			Tracker.prototype.load.apply(this, arguments);

			this.warn('Enabled');

			return true;
		},

		loadGTAG: function() {
			if ( !this.isTrackingEnabled() ) return;
			var self = this;
			CMP.promise().done(function (consentState) {
				self.warn('Consent =', consentState);
				var consent = {};
				if (!consentState.statistics) {
					self.log('Disabling cookies...');
					consent.ad_storage = 'denied';
					consent.analytics_storage = 'denied';
				} else {
					self.log('Enabling cookies...');
					consent.ad_storage = 'granted';
					consent.analytics_storage = 'granted';
				}
				window.gtag('consent', 'default', consent);

				var code = self.getCode() || '';
				self.log('Loading library', {code: code});
				var elem = document.createElement('script');
				var script = document.getElementsByTagName('script')[0];
				elem.async = 1;
				elem.type = 'text/javascript';
				elem.setAttribute('data-cookieconsent', 'ignore');
				elem.src = '//www.googletagmanager.com/gtag/js?id=' + code;
				script.parentNode.insertBefore(elem, script);
			});
		},

		setUserPropertiesSettings: function() {
			var properties = this.getUserProperties();
			this.log('User properties:', properties);
			window.gtag('set', {
				send_to: this.getCode(),
				user_properties: properties
			});
		},

		isEventEnabled: function(event) {
			var filters = this.getEventFilter();
			if (filters === null) return true;
			return filters.indexOf(event) >= 0;
		},

		getCampaignProperties: function() {
			return {
				campaign: {
					id: LoaderConfigSrvc.get('utm_id') || undefined,
					source: LoaderConfigSrvc.get('utm_source') || undefined,
					medium: LoaderConfigSrvc.get('utm_medium') || undefined,
					name: LoaderConfigSrvc.get('utm_campaign') || undefined,
					term: LoaderConfigSrvc.get('utm_term') || undefined,
					content: LoaderConfigSrvc.get('utm_content') || undefined
				}
			};
		},

		populatePayload: function(event, payload) {
			var p = $.extend(true, {}, this.getProperties(), this.getCampaignProperties(), payload || {});
			p.send_to = this.getCode();
			return p;
		},

		trapEvent: function(event, payload) {
			if ( !this.isTrackingEnabled() ) return;
			if ( !this.isEventEnabled(event) ) return;
			var eventPayload = this.populatePayload(event, payload);
			this.log('Event:', event, eventPayload);
			window.gtag('event', event, eventPayload);
		},

		detectAdblock: function() {
			var testAd = $('.adsbox')[0];

			if ( testAd && testAd.offsetHeight === 0 ) {
				this.warn('AdBlock detected !');
				this.adBlockDetected = true;
			}
		},

		trackView: function(){
			var payload = this.getProperties();
			this.trapEvent('page_view', payload);
		},

		trackMini: function(){
			var payload = this.getProperties();
			this.trapEvent('page_view', payload);
		},

		trackEvent: function(event, label, value) {
			console.log('Tracking', event, label, value);
			switch ( event ) {
				case 'view':
					this.trapEvent('view_change', { view: $S.view() });
					return;
					break;
				case 'state':
					this.trapEvent('state_change', { state: $S.state() });
					return;
					break;
				case 'mode':
					this.trapEvent('mode_change', { mode: $S.mode() });
					return;
					break;
				case 'fullscreen':
					this.trapEvent('mode_change', { mode: $S.mode() });
					return;
					break;
				case 'search-query':
					this.trapEvent('search', { search_term: label, results: value });
					return;
					break;

				case $C.ACTION_FULLLEFT:
					if (BookSrvc.direction() === $C.DIRECTION_LTR) {
						this.trapEvent('first_page', {});
					} else {
						this.trapEvent('last_page', {});
					}
					return;
					break;

				case $C.ACTION_FULLRIGHT:
					if (BookSrvc.direction() === $C.DIRECTION_LTR) {
						this.trapEvent('last_page', {});
					} else {
						this.trapEvent('first_page', {});
					}
					return;
					break;

				case $C.ACTION_LEFT:
					if (BookSrvc.direction() === $C.DIRECTION_LTR) {
						this.trapEvent('previous_page', {});
					} else {
						this.trapEvent('next_page', {});
					}
					return;
					break;

				case $C.ACTION_RIGHT:
					if (BookSrvc.direction() === $C.DIRECTION_LTR) {
						this.trapEvent('next_page', {});
					} else {
						this.trapEvent('previous_page', {});
					}
					return;
					break;

				case $C.ACTION_FIRST:
					this.trapEvent('first_page', {});
					return;
					break;

				case $C.ACTION_LAST:
					this.trapEvent('last_page', {});
					return;
					break;

				case $C.ACTION_PREVIOUS:
					this.trapEvent('previous_page', {});
					return;
					break;

				case $C.ACTION_NEXT:
					this.trapEvent('next_page', {});
					return;
					break;

				case $C.ACTION_PRINT:
					switch ( label )
					{
						case 'all':
							this.trapEvent('print_all');
							return;
							break;
						case 'page':
							this.trapEvent('print_page', { page_number: value });
							return;
							break;
					}
					break;
			}

			var props = {};
			var eventName = event;
			if ( label ) eventName += '_' + label;
			if ( value ) props.value = value;
			this.trapEvent(eventName, props);
		},

		trackPages: function(pages){
			_.each(pages, function(page){
				this.trapEvent('page', { page_number: page });
			}, this);
		},

		trackLinkClick: function(page, type, to){
			var action = 'link_click';
			var payload = {
				page_number: page
			}

			switch ( type ) {
				case $C.LINK_TYPE_EXTERNAL:
					payload.link_type = 'external';
					payload.link_url = to;
					break;

				case $C.LINK_TYPE_INTERNAL:
					payload.link_type = 'internal';
					payload.link_to = parseInt(to);
					break;

				case $C.LINK_TYPE_AUDIO:
					payload.link_type = 'audio';
					payload.link_url = to;
					break;

				case $C.LINK_TYPE_VIDEO:
					payload.link_type = 'video';
					var pieces = to.split(':');
					if ( ['youtube', 'dailymotion', 'vimeo'].indexOf(pieces[0].toLowerCase()) >= 0 ) {
						payload.link_provider = pieces[0].toLowerCase();
						payload.link_video_id = pieces[1];
					} else {
						payload.link_url = to;
					}
					break;

				case $C.LINK_TYPE_FOCUS:
					payload.link_type = 'focus';
					break;

				case $C.LINK_TYPE_SHOPPING:
					payload.link_type = 'shopping';
					payload.link_url = to;
					break;
			}

			this.trapEvent(action, payload);
		},

		getShareByType: function(type) {
			switch(type) 
			{
				case 'fbk':
					type = 'facebook';
					break;

				case 'twt':
					type = 'twitter';
					break;

				case 'gpl':
					type = 'google';
					break;

				case 'ptr':
					type = 'pinterest';
					break;

				case 'tbr':
					type = 'tumblr';
					break;

				case 'lki':
					type = 'linkedin';
					break;

				case 'vdo':
					type = 'viadeo';
					break;

				case 'eml':
					type = 'email';
					break;

				case 'ebd':
					type = 'embed';
					break;

				default:
					type = 'url';
					break;
			}

			return type;
		},

		trackShare: function(type) {
			var shareType = this.getShareByType(type);
			this.trapEvent('share', {method: shareType});
		},

		trackDownload: function() {
			this.log('Download');
			this.trapEvent('download');
		}

	});

});
