/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define('proto-decoder-book-toc',["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.BookToc = (function() {
    
        var BookToc = {};
    
        BookToc.Document = (function() {
    
            function Document(p) {
                this.items = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            Document.prototype.version = 0;
            Document.prototype.items = $util.emptyArray;
    
            Document.create = function create(properties) {
                return new Document(properties);
            };
    
            Document.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                    w.uint32(8).uint32(m.version);
                if (m.items != null && m.items.length) {
                    for (var i = 0; i < m.items.length; ++i)
                        $root.BookToc.Document.TocItem.encode(m.items[i], w.uint32(18).fork()).ldelim();
                }
                return w;
            };
    
            Document.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.BookToc.Document();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.version = r.uint32();
                            break;
                        }
                    case 2: {
                            if (!(m.items && m.items.length))
                                m.items = [];
                            m.items.push($root.BookToc.Document.TocItem.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };
    
            Document.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/BookToc.Document";
            };
    
            Document.TocItem = (function() {
    
                function TocItem(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }
    
                TocItem.prototype.level = 0;
                TocItem.prototype.name = "";
                TocItem.prototype.page = 0;
    
                TocItem.create = function create(properties) {
                    return new TocItem(properties);
                };
    
                TocItem.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.level != null && Object.hasOwnProperty.call(m, "level"))
                        w.uint32(8).uint32(m.level);
                    if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                        w.uint32(18).string(m.name);
                    if (m.page != null && Object.hasOwnProperty.call(m, "page"))
                        w.uint32(24).uint32(m.page);
                    return w;
                };
    
                TocItem.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.BookToc.Document.TocItem();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.level = r.uint32();
                                break;
                            }
                        case 2: {
                                m.name = r.string();
                                break;
                            }
                        case 3: {
                                m.page = r.uint32();
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };
    
                TocItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/BookToc.Document.TocItem";
                };
    
                return TocItem;
            })();
    
            return Document;
        })();
    
        return BookToc;
    })();

    return $root;
});

