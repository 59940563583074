define('service-config-loader',[
	'service-config'
], function (Config) {

	return Config.extend({}, {

		defaults: {

			// Force beta flag for sandboxed mode
			// [ALL]
			beta: false,

			// Build ID
			// [ALL]
			buildid: '0000-000000',

			// JWT access token
			// [ALL]
			accesstoken: null,

			// Report javascript errors using Raven.js
			// [ALL]
			reportjs: false,

			// Force the Flash viewer into its own iframe for sameDomain allowScriptAccess
			sandboxed: null,

			// Force a specific viewer (desktop, flash, mobile)
			// [LOADER]
			forceviewer: '',

			// Force a specific type of pages (svg, jpg)
			// [DESKTOP, MOBILE]
			pagetype: '',

			// JPEG page type is available
			// [DESKTOP, MOBILE, FLASH]
			html5: false,

			// Force the language
			language: null,

			// DEPRECATED => language
			// Force the language
			// [DESKTOP, FLASH, MOBILE, FALLBACK]
			langid: null,

			// Force the mode: mini, viewer, proxy
			// [DESKTOP,FLASH]
			mode: null,

			// Default view (book, slide, scroll, index)
			// [DESKTOP, FLASH]
			view: null,

			// DEPRECATED => view
			// Default view (book, slide, scroll, index)
			// [DESKTOP, FLASH]
			viewmodeatstart: null,

			// Force the page
			// [DESKTOP, FLASH, MOBILE, FALLBACK]
			page: null,

			// Opacity of the page shadow when not zooming (0-100)
			// [DESKTOP, FLASH, MOBILE]
			pagefxopacity: null,

			// Opacity of the page shadow when zooming (0-100)
			// [DESKTOP, FLASH, MOBILE]
			pagefxopacityonzoom: null,

			// Hard covers for page flip
			// [DESKTOP]
			hardcovers: false,

			// Hard pages for all page flip
			// [DESKTOP]
			hardpages: false,

			// Hard pages for all page flip
			// [DESKTOP]
			usepagecurl: false,

			// Zoom mode (cursor, drag)
			// [DESKTOP]
			zoommode: null,

			// Maximum level of zoom in percent of 1:1 (0+)
			// [DESKTOP, FLASH, MOBILE]
			zoommax: 500,

			// Default volume (0-100)
			// [DESKTOP, FLASH, MOBILE]
			volume: 75,

			// Background color (hexadecimal color value without the #)
			// [DESKTOP, FLASH, MOBILE]
			bgcolor: null,

			// Force the skin (PREMIUM, PLATINUM only)
			// [DESKTOP,FLASH]
			skinurl: null,

			// Force the styles (PREMIUM, PLATINUM only)
			// [DESKTOP, FLASH, MOBILE]
			styleurl: null,

			// Force the URL shared (PLATINUM only)
			// [DESKTOP, FLASH, MOBILE]
			shareurl: null,

			// Force the locales
			// [DESKTOP, FLASH, MOBILE, FALLBACK]
			localesurl: null,

			// Version of the Javascript API
			// Can you version 1 to keep old integration
			// Version 1: custom formatted string
			// Version 2: JSON
			// [DESKTOP, FLASH, MOBILE]
			jsapiversion: 1,

			// Enable the Javascript API
			// [DESKTOP, FLASH, MOBILE]
			jsapienabled: true,

			// JS API callbacks
			// [FLASH]
			jsapiinitcallback: '__initCallback',
			jsapichangecallback: '__changeCallback',
			jsapimsgcallback: '__msgCallback',

			// Tracker source parameter (intern, embed, widget)
			// [DESKTOP, FLASH, MOBILE, FALLBACK]
			trackersource: 'embed',

			// Mini mode click behavior (public, view, embed, url)
			// [DESKTOP, FLASH, MOBILE, FALLBACK]
			clickto: null,

			// Mini mode click target (_blank, _self)
			// [DESKTOP, FLASH, MOBILE, FALLBACK]
			clicktarget: '_blank',

			// Mini mode click target URL (PLATINUM only)
			// [DESKTOP, FLASH, MOBILE, FALLBACK]
			clicktourl: null,

			// Mini mode autoflip
			// [DESKTOP, FLASH, MOBILE, FALLBACK]
			autoflip: null,

			// Show the share menu when going from fullscreen to mini
			// [DESKTOP, FLASH, MOBILE, FALLBACK]
			showsharemenu: true,

			// Change the version of the SWF loader
			// [FLASH]
			loaderversion: null,

			// Change the version of the SWF viewer
			// [FLASH]
			viewerversion: null,

			// Change the wmode of the SWF viewer
			// [FLASH]
			wmode: 'opaque',

			// Disable the history manager
			// [DESKTOP, FLASH, MOBILE, FALLBACK]
			disablehistory: false,

			// Disable the mousewheel completely
			// [DESKTOP, FLASH]
			disablemousewheel: false,

			// DEPRECATED => disabletracking
			// Disable customer GA tracking
			// [DESKTOP, MOBILE]
			disableaccountga: false,

			// DEPRECATED => disabletracking
			// Disable GA tracking
			// [DESKTOP, MOBILE]
			disablega: false,

			// Disable GA tracking
			// [DESKTOP, MOBILE]
			disabletracking: false,

			// Use EMBED tag instead of IMG for page loading
			// [DESKTOP, MOBILE]
			useinlinesvg: false,

			// Do not display the cover fo mobile viewer
			// [MOBILE]
			mobiledirect: false,

			// Prevent cookies
			// [DESKTOP, MOBILE]
			cookies: true,

			// Force exact match search
			// [DESKTOP, MOBILE]
			searchexactmatch: false,

			// Force full words search
			// [DESKTOP, MOBILE]
			searchfullwords: false,

			// Google Analytics campaign
			utm_id: '',
			utm_source: '',
			utm_medium: '',
			utm_campaign: '',
			utm_term: '',
			utm_content: ''
		},

		// Cleanup the background color to avoid XSS
		getBgColor: function(prefix){
			prefix || (prefix = '');
			var bgColor = this.get('bgcolor');
			if ( !bgColor ) return null;
			bgColor = bgColor.replace(/[^0-9abcdef]+/i, '').substring(0, 6);
			return prefix + bgColor;
		}

	});


});

