define('manager-viewport',[
	'underscore',
	'jquery',
	'constants',
	'util',
	'manager',
	'manager-event',
	'model-rect',
	'ifvisible',
	'screenfull'
], function (_, $, $C, Util, Manager, EventManager, Rect, ifVisible) {

	var ViewportManager = Manager.extend({}, {

		_rect: new Rect(
			0,
			0,
			Math.max(1, window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth),
			Math.max(1, window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)
		),
		debouncedHandler: null,

		IsFullscreen: false,

		enable: function()
		{
			if ( !this.IsDisabled ) return;

			console.log('ViewportManager => Enabled');

			this.debouncedHandler = _.throttle(this.onResizeHandler.bind(this), 500, {leading: false});

			$(window).on('resize', this.debouncedHandler);

			if ( screenfull.enabled ) EventManager.on(screenfull.raw.fullscreenchange, this.onFullscreenHandler, this);

			if (!this.f11Handler)
			{
				var self = this;
				this.f11Handler = $.proxy(function(e) {
					if ( self.IsDisabled ) return;
					switch (e.key)
					{
						case 'F11':
							console.log('ViewportManager => F11');
							this.toggleFullscreen();
							e.preventDefault();
							e.stopPropagation();
							break;
					}
				}, this);

				EventManager.on('keydown', this.f11Handler);
			}


			// ifVisible.on('blur', this.onVisibleHandler.bind(this));
			// ifVisible.on('focus', this.onVisibleHandler.bind(this));
			ifVisible.on('idle', this.onVisibleHandler.bind(this));
			ifVisible.on('wakeup', this.onVisibleHandler.bind(this));

			Manager.enable.apply(this, arguments);
		},
		disable: function()
		{
			if ( this.IsDisabled ) return;

			console.log('ViewportManager => Enabled');

			$(window).off('resize', this.debouncedHandler);

			if ( screenfull.enabled ) EventManager.off(screenfull.raw.fullscreenchange, this.onFullscreenHandler, this);
			if ( this.f11Handler ) EventManager.off('keydown', this.f11Handler);

			// ifVisible.off('blur', this.onVisibleHandler.bind(this));
			// ifVisible.off('focus', this.onVisibleHandler.bind(this));
			ifVisible.off('idle', this.onVisibleHandler.bind(this));
			ifVisible.off('wakeup', this.onVisibleHandler.bind(this));


			Manager.disable.apply(this, arguments);
		},

		onVisibleHandler: function(e){

			var visibility = ifVisible.now() ? 'visible': 'hidden';

			EventManager.trigger('context:global', {type: visibility});
		},

		getRect: function(){
			return this._rect;
		},

		width: function(){
			return this._rect.width();
		},
		height: function(){
			return this._rect.height();
		},

		onFullscreenHandler: function(e){
			this.fullscreen( !this.fullscreen() );

			EventManager.trigger('context:fullscreen', {type: this.fullscreen() ? 'enter' : 'leave'});
		},

		fullscreen: function(fs){
			if ( _.isUndefined(fs) ) return this.IsFullscreen;
			this.IsFullscreen = !!fs;
			console.log('ViewportManager => Fullscreen:', this.IsFullscreen);
			return this;
		},

		detectIfFullscreen: function(){
			var isFullscreen = screenfull.isFullscreen;

			if ( Util.isEmbedded() )
			{
				if ( !Util.isCrossDomain() )
				{
					if ( parent.screenfull )
					{
						isFullscreen = parent.screenfull.isFullscreen;
					}
					else
					{
						console.error('Viewer parent should have screenfull library available');
					}
				}
				// Else we cannot detect is we are in fullscreen (crossdomain issue)
			}

			return isFullscreen;
		},

		enterFullscreen: function(element)
		{
			console.warn('ViewportManager => ENTER fullscreen');

			element || (element = document.documentElement);

			if ( screenfull.enabled )
			{
				EventManager.trigger('context:visible', {type: 'hidden', target: this});
				screenfull.request( element );
				return true;
			}

			return false;
		},

		exitFullscreen: function()
		{
			console.warn('ViewportManager => EXIT fullscreen');

			if ( screenfull.isFullscreen )
			{
				// Only hide when fullscreen is current window
				EventManager.trigger('context:visible', {type: 'hidden', target: this});

				screenfull.exit();
			}
			else
			{
				if ( Util.isEmbedded() )
				{
					if ( !Util.isCrossDomain() )
					{
						if ( parent.screenfull && parent.screenfull.element )
						{
							return parent.screenfull.exit();
						}
						else
						{
							console.error('Viewer parent should have screenfull library available');
							return false;
						}
					}
				}
			}
  		},

  		toggleFullscreen: function()
  		{
  			this.fullscreen( !this.IsFullscreen );
  			if (this.fullscreen()) {
  				this.enterFullscreen();
  			} else {
  				this.exitFullscreen();
  			}
  		},

  		onResizeHandler: function(e)
		{
			console.log('ViewportManager => Resize');

			this.invalidate();
		},

		invalidate: function(force){
			// These should not be 0 !!!
			var w = Math.max(1, window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
			var h = Math.max(1, window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);

			// Avoid resize where width does not change on mobile devices
			// like keyboard sliding in, etc
			if ( !force && Util.isMobile() && w == this._rect.width() ) return;

			// Update viewport size
			this._rect.width( w );
			this._rect.height( h );

			console.log('ViewportManager => Invalidate', this._rect);

			EventManager.trigger('context:resize', {type: 'init', target: this});

			EventManager.trigger('context:resize', {type: 'before', target: this});

			EventManager.trigger('context:resize', {type: 'after', target: this});
		}
	});

	var methods = ['addClass', 'removeClass', 'hasClass', 'toggleClass', 'css'];

	// Mix in each Underscore method as a proxy to `Collection#models`.
	_.each(methods, function(method) {
		ViewportManager[method] = function() {
			return $('body')[method].apply($('body'), arguments);
		};
	});

	return ViewportManager;

});
