define('notification',[
	'jquery',
	'underscore',
	'status',
	'util',
	'ui-view',
	'helpers/notifications/template',
	'manager-viewport',
	'jquery-hammer'
], function ($, _, $S, Util, UIView, Template, ViewportManager) {

	return UIView.extend(
		{
			_promise: null,

			className: 'notification',

			attributes: {
				'role': 'alert'
			},

			template: Template.notification,

			msg: '',
			duration: 0,

			initialize: function(options)
			{
				options || (options = {});

				_.extend(this, _.pick(options, ['msg', 'duration']));

				this._promise = $.Deferred();

				this.render();

				if ( Util.isMobile() )
				{
					this.$el.hammer({tap_always: false}).bind('tap', this.onClickHandler.bind(this));
				}
				else
				{
					this.$el.on($S.Events.click, this.onClickHandler.bind(this));
				}
			},
			render: function(){
				this.update();
			},
			promise: function(){
				return this._promise;
			},
			show: function()
			{
				// Defer the shown class toggle to make sure the DOM is refreshed
				_.delay( this.addClass.bind(this), 60, 'shown');

				// Auto hide
				if ( this.duration > 0 ) _.delay( this.hide.bind(this), this.duration );

				return this;
			},
			onClickHandler: function(e){
				this._promise.resolve('clicked');
				this.hide();
				return false;
			},
			hide: function()
			{
				this.removeClass('shown');

				this._promise.resolve('ignored');

				// Delay the destruction of the view until the CSS transition is over
				_.delay( this.clear.bind(this), 1000 );

				return this;
			},
			update: function(msg){
				this.msg = msg || this.msg;
				this.$el.html( this.template( this ) );
			},
			clear: function()
			{
				this.remove();
			}
		}
	);

});
