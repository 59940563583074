define('util-url',['underscore', 'urljs'], function (_){
  'use strict';

	return {
		parent: _.once(function(){
			if ( parent != window )
			{
				try
				{
					var url = parent.location.href;
				}
				catch(e)
				{
					var referrer = URL( document.referrer );
					var self = this.self();
					// We caught an exception trying to access the parent frame
					// We test to see if the referer is the same domain as the current domain
					// Since it cannot be, we cannot use the referrer (loaded new publication?)
					// So, we are using the fsociety.org domain as a dummy substitute
					url = referrer.host() == self.host() ? window.location.protocol + '//fsociety.org' : referrer;
				}
			}
			else
			{
				url = window.location.href;
			}
			return URL( url );
		}),

		self: _.once(function(){
			return URL( document.location.href );
		}),
		prepare: function(url){
			// We cannot use the base href because it messes up the SVG use GFXs
			// So we need to reconstruct the
			if ( window.location.protocol == 'data:' )
			{
				// Detect the host from the opener window
				var host = Calameo.defaults.base.url;

				// Bypass protocol relative URL
				if ( url.indexOf('//') === 0 ) url = URL( url ).scheme('https').toString();

				// Bypass path relative URL
				if ( url.indexOf('/') === 0 ) url = URL( url ).scheme('https').host( host ).toString();

				// No need to overwrite HTTP/S because data: is another protocol
			}
			else
			{
				// Use relative protocol for HTTP/S
				if ( this.isSecured() && !this.isSecured(url) ) url = this.relativizeProtocol( url );
			}

			return url;
		},
		isSecured: function(url){
			url || (url = window.location.href);
			return new String(url).match(/^https:/i) && true;
		},
		relativizeProtocol: function(url){
			return url.replace(/^https?:/i, '');
		},
		redirectTo: function(url){
			window.location.href = url;
		},
		redirectTopTo: function(url){
			console.debug('Util => Redirecting top to:', url);
			try
			{
				window.top.location.href = url;
			}
			catch ( e )
			{
				console.error(e);
			}
		},
		redirectPost: function(url, values, target){
		    var $form = $('<form>')
		      .attr('method', 'POST')
		      .attr('action', url);

		    if (target) {
		      $form.attr('target', target);
		    }

		    Object.keys(values).forEach(function (name) {
		    	var value = values[name];
		        $form.append(
		        	$('<input>')
		        		.attr('type', 'hidden')
						.attr('name', name)
						.attr('value', value)
				);
		    });

		    console.info('Redirecting to URL using POST:', url, values, target);

		    $('body').append($form);

		    $form.submit();
		},
		openWindow: function(url, name){
			console.debug('Util => Opening a new window:', url);
			try
			{
				var win = window.open(url || '', name || '');
				if ( _.isEmpty(win) ) throw new Error('Error opening a new window');
				return win;
			}
			catch ( e )
			{
				console.error(e);
			}
		},
		getParameter: function (name, url) {
			url || (url = window.location.href);
			return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url)||[,""])[1].replace(/\+/g, '%20'))||null;
		},
		addParameter: function(url, key, value) {
			return url + ( url.indexOf('?') >= 0 ? '&' : '?' ) + key + '=' + encodeURIComponent(value);
		},
		addParameters: function(url, parameters) {
			var params = _.reduce(Object.entries(parameters), function(p, entry) {
				p.push(entry[0] + '=' + encodeURIComponent(entry[1]));
				return p;
			}, []);
			return url + ( url.indexOf('?') >= 0 ? '&' : '?' ) + params.join('&');
		}
	};
});

