/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define('proto-decoder-book-text',["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.BookText = (function() {
    
        var BookText = {};
    
        BookText.Document = (function() {
    
            function Document(p) {
                this.pages = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            Document.prototype.version = 0;
            Document.prototype.pages = $util.emptyArray;
    
            Document.create = function create(properties) {
                return new Document(properties);
            };
    
            Document.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                    w.uint32(8).uint32(m.version);
                if (m.pages != null && m.pages.length) {
                    for (var i = 0; i < m.pages.length; ++i)
                        $root.BookText.Document.Page.encode(m.pages[i], w.uint32(34).fork()).ldelim();
                }
                return w;
            };
    
            Document.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.BookText.Document();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.version = r.uint32();
                            break;
                        }
                    case 4: {
                            if (!(m.pages && m.pages.length))
                                m.pages = [];
                            m.pages.push($root.BookText.Document.Page.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };
    
            Document.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/BookText.Document";
            };
    
            Document.Page = (function() {
    
                function Page(p) {
                    this.chunks = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }
    
                Page.prototype.pageNumber = 0;
                Page.prototype.width = 0;
                Page.prototype.height = 0;
                Page.prototype.chunks = $util.emptyArray;
    
                Page.create = function create(properties) {
                    return new Page(properties);
                };
    
                Page.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.pageNumber != null && Object.hasOwnProperty.call(m, "pageNumber"))
                        w.uint32(8).uint32(m.pageNumber);
                    if (m.width != null && Object.hasOwnProperty.call(m, "width"))
                        w.uint32(16).uint32(m.width);
                    if (m.height != null && Object.hasOwnProperty.call(m, "height"))
                        w.uint32(24).uint32(m.height);
                    if (m.chunks != null && m.chunks.length) {
                        for (var i = 0; i < m.chunks.length; ++i)
                            $root.BookText.Document.Page.Chunk.encode(m.chunks[i], w.uint32(34).fork()).ldelim();
                    }
                    return w;
                };
    
                Page.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.BookText.Document.Page();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.pageNumber = r.uint32();
                                break;
                            }
                        case 2: {
                                m.width = r.uint32();
                                break;
                            }
                        case 3: {
                                m.height = r.uint32();
                                break;
                            }
                        case 4: {
                                if (!(m.chunks && m.chunks.length))
                                    m.chunks = [];
                                m.chunks.push($root.BookText.Document.Page.Chunk.decode(r, r.uint32()));
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };
    
                Page.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/BookText.Document.Page";
                };
    
                Page.Chunk = (function() {
    
                    function Chunk(p) {
                        this.offsets = [];
                        this.matrix = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }
    
                    Chunk.prototype.text = "";
                    Chunk.prototype.height = 0;
                    Chunk.prototype.offsets = $util.emptyArray;
                    Chunk.prototype.matrix = $util.emptyArray;
    
                    Chunk.create = function create(properties) {
                        return new Chunk(properties);
                    };
    
                    Chunk.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.text != null && Object.hasOwnProperty.call(m, "text"))
                            w.uint32(10).string(m.text);
                        if (m.height != null && Object.hasOwnProperty.call(m, "height"))
                            w.uint32(21).float(m.height);
                        if (m.offsets != null && m.offsets.length) {
                            w.uint32(26).fork();
                            for (var i = 0; i < m.offsets.length; ++i)
                                w.int32(m.offsets[i]);
                            w.ldelim();
                        }
                        if (m.matrix != null && m.matrix.length) {
                            w.uint32(34).fork();
                            for (var i = 0; i < m.matrix.length; ++i)
                                w.float(m.matrix[i]);
                            w.ldelim();
                        }
                        return w;
                    };
    
                    Chunk.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.BookText.Document.Page.Chunk();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.text = r.string();
                                    break;
                                }
                            case 2: {
                                    m.height = r.float();
                                    break;
                                }
                            case 3: {
                                    if (!(m.offsets && m.offsets.length))
                                        m.offsets = [];
                                    if ((t & 7) === 2) {
                                        var c2 = r.uint32() + r.pos;
                                        while (r.pos < c2)
                                            m.offsets.push(r.int32());
                                    } else
                                        m.offsets.push(r.int32());
                                    break;
                                }
                            case 4: {
                                    if (!(m.matrix && m.matrix.length))
                                        m.matrix = [];
                                    if ((t & 7) === 2) {
                                        var c2 = r.uint32() + r.pos;
                                        while (r.pos < c2)
                                            m.matrix.push(r.float());
                                    } else
                                        m.matrix.push(r.float());
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };
    
                    Chunk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/BookText.Document.Page.Chunk";
                    };
    
                    return Chunk;
                })();
    
                return Page;
            })();
    
            return Document;
        })();
    
        return BookText;
    })();

    return $root;
});

