define('manager-event',[
	'jquery',
	'manager'
], function ($, Manager) {

	return Manager.extend({}, {
		IsDisabled: false,

		on: function(evt, callback, context)
		{
			//console.log('EventManager => On "' + evt + '"');
			$(document).on(evt, this.applyContext(callback, context));
		},
		one: function(evt, callback, context)
		{
			//console.log('EventManager => One "' + evt + '"');
			$(document).one(evt, this.applyContext(callback, context));
		},
		off: function(evt, callback, context)
		{
			//console.log('EventManager => Off "' + evt + '"');
			$(document).off(evt, this.applyContext(callback, context));
		},
		delegate: function(selector, evt, callback, context)
		{
			$(document).delegate(selector, evt, this.applyContext(callback, context));
		},
		undelegate: function(selector, evt, callback, context)
		{
			$(document).undelegate(selector, evt, this.applyContext(callback, context));
		},
		trigger: function(evt, data)
		{
			if ( this.IsDisabled ) return;

			console.log('EventManager => ' + evt, data);

			$(document).trigger(evt, data);
		},
		applyContext: function(callback, context)
		{
			return context ? $.proxy(callback, context) : callback;
		}
	});

});
