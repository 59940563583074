define('tracker',[
	'jquery',
	'underscore',
	'util'
], function ($, _, Util) {

	var Tracker = function(){
		return this.initialize.apply(this, arguments);
	};

	Tracker.extend = Util.extend;

	_.extend(Tracker.prototype, {

		id: '',

		IsLoaded: false,

		initialize: function(){
			this.load();
		},

		load: function(){
			// Load libraries
			this.IsLoaded = true;
		},

		clear: function(){
			// To overwrite
		},

		trackView: function(){
			// To Overwrite
		},

		trackMini: function(){
			// To Overwrite
		},

		trackEvent: function(category, action, label, value){
			// To Overwrite
		},

		trackPages: function(pages){

		},

		trackPageTime: function(pages, elapsed){

		},

		trackLinkClick: function(page, type, url){

		},

		trackShare: function(type){

		},

		trackDownload: function(){

		}

	});

	return Tracker;

});
