define('constants',[
], function () {

	return {
		API_YOUTUBE_KEY: '',

		STACK_SIZE: 8, // 8 minimum to avoid scroll view from being unable to load all items
		VIEWPORT_MIN_SIZE: 350, // Auto switch to mini

		LOADER_MODE_MINI: 'mini',
		LOADER_MODE_VIEWER: 'viewer',

		DEVICE_DESKTOP: 'desktop',
		DEVICE_PHONE: 'phone',
		DEVICE_TABLET: 'tablet',

		VIEW_BOOK: 'book',
		VIEW_SLIDE: 'slide',
		VIEW_SCROLL: 'scroll',
		VIEW_INDEX: 'index',
		VIEW_THUMBNAILS: 'thumbnails', // Obsolete
		VIEW_SWIPE: 'swipe',
		VIEW_FALLBACK: 'fallback',

		MODE_MINI: 'mini',
		MODE_NORMAL: 'normal',
		MODE_FULLSCREEN: 'fullscreen',

		STATE_NORMAL: 'normal',
		STATE_ZOOM: 'zoom',

		ACCOUNT_MODE_PLATINUM: 'PLATINUM',
		ACCOUNT_MODE_PREMIUM: 'PREMIUM',
		ACCOUNT_MODE_FREE: 'FREE',

		DIRECTION_LTR: 'left',
		DIRECTION_RTL: 'right',

		PAGE_TYPE_SVG: 'svg',
		PAGE_TYPE_JPG: 'jpg',
		PAGE_TYPE_THUMB: 'thumb',

		TOOLTIP_STYLE_ACTION: 'tsaction',
		TOOLTIP_STYLE_LINK: 'tslink',
		TOOLTIP_STYLE_VIDEO: 'tsvideo',
		TOOLTIP_STYLE_AUDIO: 'tsaudio',
		TOOLTIP_STYLE_PAGE: 'tspage',
		TOOLTIP_STYLE_PAGE_SCROLL: 'tspagescroll',

		ORIENTATION_HORIZONTAL: 'horizontal',
		ORIENTATION_VERTICAL: 'vertical',

		AD_POSITION_TOP: 'top',
		AD_POSITION_BOTTOM: 'bottom',

		SKIN_NS_V1: '//skin.calameo.com/1.0',
		SKIN_NS_V2: '//skin.calameo.com/2.0',

		ZOOM_DRAG: 'drag',
		ZOOM_CURSOR: 'cursor',

		FX_FLIP: 'flip',

		CLICKTO_OVERVIEW: 'public',
		CLICKTO_READ: 'view',
		CLICKTO_FULLSCREEN: 'embed',
		CLICKTO_URL: 'url',

		VIDEO_SRC_USER: 'user',
		VIDEO_SRC_YOUTUBE: 'youtube',
		VIDEO_SRC_DAILYMOTION: 'dailymotion',
		VIDEO_SRC_VIMEO: 'vimeo',

		AUDIO_SRC_CUSTOM: 'custom',
		AUDIO_SRC_SOUNDCLOUD: 'soundcloud',

		ACTION_MINI: 'mini',

		ACTION_BOOK: 'book',
		ACTION_SLIDE: 'slide',
		ACTION_SLIDE_LEGACY: 'diaporama',
		ACTION_SCROLL: 'scroll',
		ACTION_SWIPE: 'swipe',
		ACTION_INDEX: 'thumbnails',
		ACTION_FALLBACK: 'fallback',

		ACTION_SUBMENU: 'submenu',
		ACTION_HORIZONTAL_SUBMENU: 'horizontalsubmenu',
		ACTION_VERTICAL_SUBMENU: 'verticalsubmenu',

		ACTION_ZOOM: 'zoom',
		ACTION_ZOOMIN: 'zoomin',
		ACTION_ZOOMOUT: 'zoomout',
		ACTION_ZOOM_DRAG: 'zoomdrag',
		ACTION_ZOOM_CURSOR: 'zoomcursor',

		ACTION_FIRST: 'firstpage',
		ACTION_PREVIOUS: 'previouspage',
		ACTION_NEXT: 'nextpage',
		ACTION_LAST: 'lastpage',

		ACTION_LEFT: 'left',
		ACTION_RIGHT: 'right',

		ACTION_FULLLEFT: 'fullleft',
		ACTION_FULLRIGHT: 'fullright',

		ACTION_FULLSCREEN: 'fullscreen',

		ACTION_DOWNLOAD: 'download',

		ACTION_VOLUME: 'volume',
		ACTION_VOLUME_UP: 'volumeup',
		ACTION_VOLUME_DOWN: 'volumedown',

		ACTION_AUDIO_PLAY: 'audioplay',
		ACTION_AUDIO_PAUSE: 'audiopause',
		ACTION_AUDIO_PREVIOUS: 'audioprevious',
		ACTION_AUDIO_NEXT: 'audionext',

		ACTION_BRANDING: 'branding',
		ACTION_OVERVIEW: 'overview',
		ACTION_READ: 'read',

		ACTION_SHARE: 'share',
		ACTION_SHARE_FACEBOOK: 'shareonfacebook',
		ACTION_SHARE_TWITTER: 'shareontwitter',
		ACTION_SHARE_GPLUS: 'shareongplus',
		ACTION_SHARE_PINTEREST: 'shareonpinterest',
		ACTION_SHARE_TUMBLR: 'shareontumblr',
		ACTION_SHARE_LINKEDIN: 'shareonlinkedin',
		ACTION_SHARE_EMAIL: 'sharebyemail',
		ACTION_SHARE_EMBED: 'shareembed',
		ACTION_SHARE_URL: 'sharelink',

		ACTION_TOC: 'toc',
		ACTION_RELATED: 'related',
		ACTION_RELATED_LEGACY: 'subscription',
		ACTION_SEARCH: 'search',

		ACTION_PRINT_ALL: 'printall',
		ACTION_PRINT_PAGE: 'printpage',
		ACTION_PRINT: 'print',

		ACTION_PAGE: 'page',
		ACTION_URL: 'url',

		LINK_TYPE_EXTERNAL: 1,
		LINK_TYPE_INTERNAL: 2,
		LINK_TYPE_AUDIO: 3,
		LINK_TYPE_MEDIA: 4,
		LINK_TYPE_EMAIL: 5,
		LINK_TYPE_VIDEO: 6,
		LINK_TYPE_FOCUS: 8,
		LINK_TYPE_SHOPPING: 9,
		LINK_TYPE_INTEGRATION: 10,

		LINK_OPTION_TARGET				: 'tg',
		LINK_OPTION_DISPLAY_MODE		: 'dm',
		LINK_OPTION_AUTO_PLAY			: 'ap',
		LINK_OPTION_STOP_MUSIC			: 'sm',
		LINK_OPTION_CLOSE_ON_PAGE_CHANGE: 'cpc',
		LINK_OPTION_CHANGE_PAGE_ON_END  : 'cpe',
		LINK_OPTION_DISPLAY_THUMBNAIL	: 'dt',
		LINK_OPTION_ZOOM_FACTOR			: 'zf',
		LINK_OPTION_INTEGRATION_PAYLOAD : 'p',
		LINK_OPTION_ICON_TYPE			: 'ict',
		LINK_OPTION_ICON_BACKGROUND_COLOR: 'icbc',
		LINK_OPTION_ICON_FOREGROUND_COLOR: 'icfc',
		LINK_OPTION_ANIMATION_TYPE		: 'ant',
		LINK_OPTION_ANIMATION_SPEED		: 'ans',
		LINK_OPTION_ANIMATION_MODE		: 'anm',
		LINK_OPTION_ANIMATION_DELAY		: 'and',

		VIDEO_DISPLAY_LIGHTBOX: 'l',
		VIDEO_DISPLAY_EMBEDDED: 'e',

		AUDIO_DISPLAY_LIGHTBOX: 'l',
		AUDIO_DISPLAY_EMBEDDED: 'e',

		AUDIO_AUTOPLAY_ONCE: 'o',
		AUDIO_AUTOPLAY_REPEAT: 'r',

		EXTERNAL_DISPLAY_BLANK: '',
		EXTERNAL_DISPLAY_SELF: '_top',
		EXTERNAL_DISPLAY_OVERLAY: 'o',

		SHOPPING_DISPLAY_BLANK: '_blank',
		SHOPPING_DISPLAY_SELF: '_top',
		SHOPPING_DISPLAY_OVERLAY: '',

		INTEGRATION_DISPLAY_OVERLAY: 'o',
		INTEGRATION_DISPLAY_EMBED: 'e',

		INTEGRATION_AUTOPLAY_ONCE: 'o',
		INTEGRATION_AUTOPLAY_REPEAT: 'r',

		TRIGGER_TYPE_OPEN: 1,
		TRIGGER_TYPE_DOWNLOAD: 2,
		TRIGGER_TYPE_PRINT: 3,

		TRIGGER_REPEATING_MODE_ONCE: 1,
		TRIGGER_REPEATING_MODE_UNTIL_DONE: 2,
		TRIGGER_REPEATING_MODE_ALWAYS: 3,

		KEY_BACKSPACE: 8,
		KEY_ENTER: 13,
		KEY_ESCAPE: 27,
		KEY_SPACE: 32,
		KEY_PAGEUP: 33,
		KEY_PAGEDOWN: 34,
		KEY_END: 35,
		KEY_HOME: 36,
		KEY_LEFT: 37,
		KEY_UP: 38,
		KEY_RIGHT: 39,
		KEY_DOWN: 40,
		KEY_NUMPAD_ADD: 107,
		KEY_NUMPAD_SUBSTRACT: 109,
		KEY_NUMPAD_1: 97,
		KEY_NUMPAD_2: 98,
		KEY_NUMPAD_3: 99,
		KEY_NUMPAD_4: 100,
		KEY_V: 86
	};


});

