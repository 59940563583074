define('manager-search',[
	'jquery',
	'underscore',
	'constants',
	'status',
	'util',
	'manager',
	'manager-event',
	'service-data-book',
	'service-data-debug',
	'manager-debug',
	'proto-decoder-book-text',
	'search-accent-map'
], function ($, _, $C, $S, Util, Manager, EventManager, BookSrvc, DebugSrvc, DebugManager, Decoder, AccentMap) {

	return Manager.extend({}, {

		_searchCapability: null,

		_pageIndexMap: {},

		MaxSnippets: 3,
		SnippetSize: 150,
		SnippetJoiner: '...',
		SnippetStartTag: '<u class="hl">',
		SnippetEndTag: '</u>',

		enable: function(){
			Manager.enable.apply(this, arguments);

			// EventManager.on('context:search', $.proxy(this.onSearchHandler, this));

			this._ensureSearchCapability();
		},

		/*
		onSearchHandler: function(e, data){
			switch ( data.type )
			{
				case 'highlight':
					var page = this.Document.pages[data.page-1];
					if ( !page ) return;

					var $canvas = data.target;
					if ( !$canvas ) return;

					var context = $canvas.get(0).getContext('2d');

					$canvas.attr({width: page.width, height: page.height});
					this
						.searchOnPage( SearchSrvc.query() )
						.then( $.proxy(function(result){
							console.log('SearchManager => Result:', result);
						}, this) );
					break;
			}
		},
		*/

		// Interface functions
		searchInDocument: function(query, options){
			if ( this.IsDisabled ) return;

			options || (options = {});

			var self = this;

			return this._ensureSearchCapability().then(
				function(){
					// Return the page that match the query
					// with the thumbnail URL and highlighted snippets
					console.log('SearchManager => Searching in document for "', query , '"');

					var regExp = self._createSearchRegExp(query, options, 'gmid');

					var page, match;
					// var snippetTag = self.SnippetStartTag + '$1' + self.SnippetEndTag;
					var result = {
						total: 0,
						start: 0,
						step: 0,
						pages: []
					};
					for ( var i = 1, ii = self._pageIndexMap.length ; i <= ii ; i++ )
					{
						regExp.lastIndex = 0;

						page = self._pageIndexMap[i-1];

						var searchedText = self._filterAccents( page.text );

						if ( match = regExp.exec( searchedText ) )
						{
							console.log('SearchManager -> Page:', page.index + 1, 'Match:', match);

							// Extract snippets
							var snippets = [],
								snippet = '',
								start = 0,
								size = 0,
								fPos = 0,
								lPos = 0,
								hasEllipsis = false;
							do
							{

								var matchLength	= match.groups.found.length;
								var start		= match.indices.groups.found[0];
								var end			= match.indices.groups.found[1];

								var before		= Math.max(0, start - Math.round( ( self.SnippetSize - matchLength ) / 2) );
								var after		= Math.min(end + Math.round( ( self.SnippetSize - matchLength ) / 2 ), page.text.length);

								var snippet = page.text.substr(before, start - before) + self.SnippetStartTag + page.text.substr(start, end - start) + self.SnippetEndTag + page.text.substr(end, after - end);

								// Get the first white space pos
								fPos = snippet.indexOf(' ');
								if ( !hasEllipsis && fPos < Math.max(0, match.index - before) )
								{
									// Truncate
									snippet = self.SnippetJoiner + snippet.substr(fPos + 1);
								}

								lPos = snippet.lastIndexOf(' ');
								if ( lPos > match.index - before + matchLength )
								{
									snippet = snippet.substr(0, lPos) + self.SnippetJoiner;
									hasEllipsis = true;
								}
								else
								{
									hasEllipsis = false;
								}

								snippets.push( snippet );
							}
							while ( ( match = regExp.exec( searchedText ) ) && snippets.length < self.MaxSnippets );

							// Build result response
							result.total++;
							result.pages.push({
								id			: i,
								pos			: page.pageNumber,
								w			: self.Document.pages[i-1].width,
								h			: self.Document.pages[i-1].height,
								sn			: snippets.join(' '),
								t			: {
									sd		: 'i',
									u		: 'p' + i + '.jpg'
								}
							});
						}

						console.log(match);
					}

					console.log('SearchManager => Result:', result);

					return result;
				}
			);
		},
		searchOnPage: function(query, options, pageNumber){
			if ( this.IsDisabled ) return;

			options || (options = {});

			var self = this;

			return this._ensureSearchCapability().then(
				function(){
					// Return chunks that match the query
					// with the offsets to draw them
					console.log('SearchManager => Searching on page', pageNumber, 'for "', query , '"');

					var page = self.Document.pages[pageNumber-1];
					var pageMap = self._pageIndexMap[pageNumber-1];

					if ( !page || !pageMap ) return {};

					var chunks = [];

					var regExp = self._createSearchRegExp(query, options, 'gmid');

					var searchedText = self._filterAccents( pageMap.text );

					var chunkMap = null,
						chunk = null,
						from = 0,
						to = 0,
						left = 0,
						right = 0,
						width = 0;

					var i = 1, ii = page.chunks.length;
					while ( result = regExp.exec( searchedText ), result )
					{
						from = result.indices.groups.found[0];
						to = result.indices.groups.found[1];

						// console.log('SearchManager => Found', from, to, chunkMap, result[0]);

						for ( i-- ; i < ii ; i++ )
						{
							chunkMap = pageMap.chunkMap[i];

							if ( chunkMap.end < from ) continue;
							if ( chunkMap.start > to ) break;

							chunk = page.chunks[i];

							from = Math.max(0, from - chunkMap.start);
							to = Math.min(chunkMap.end - chunkMap.start, to - chunkMap.start);

							var left = ( chunk.offsets[from-1] || 0 );
							var right = ( chunk.offsets[to-1] || chunk.offsets[chunk.offsets.length-1] );

							var width = ( right - left );

							// console.log(chunk, from, to, left, right);

							chunks.push({
								matrix: chunk.matrix,
								height: chunk.height,
								left: left,
								right: right,
								width: width
							});
						}
					}

					return {
						width: page.width,
						height: page.height,
						chunks: chunks
					};
				},
				function(err){

				}
			);
		},

		// *****

		// Private methods

		_ensureSearchCapability: function(){

			if ( !BookSrvc.feature('search.bin.enabled') ) return;

			if ( this._searchCapability ) return this._searchCapability;

			this._searchCapability = $.Deferred();

			this._searchCapability.done( $.proxy(function(buffer){
				this.Document = Decoder.BookText.Document.decode( buffer );
				console.log('SearchManager => Search data decoded');
				console.log('SearchManager => Decoded document:', this.Document);
				return this._parseDocument();
			}, this) );

			this._searchCapability.fail( function(msg, url){

				// Return an error
				DebugManager.error(msg, {code: 916, url: url});

				DebugSrvc
					.load({
						data: {
							type: 'error',
							error: 916,
							payload: JSON.stringify( DebugManager.getDebugData() )
						}
					});
			} );

			this._loadBinaryData();

            return this._searchCapability;
		},

		_loadBinaryData: function(){

			var url;
			var path = BookSrvc.feature('search.bin.path');
			if ( !path || !BookSrvc.feature('urlsigning.enabled') )
			{
				url = BookSrvc.feature('search.bin.url');
			}
			else
			{
				url = BookSrvc.get('domains.secured.text') + path;
			}

			url = Util.url.prepare( url );

			// Sign
			url = BookSrvc.signUrl( url );

			console.debug('SearchManager => Loading binary data:', url);

			var xhr = new XMLHttpRequest();

            xhr.open('GET', url, true); // true -> Async

            xhr.responseType = 'arraybuffer';

            xhr.onload = $.proxy(function()
            {
            	// Check the status of the response
            	if ( xhr.status >= 400 || !xhr.response )
            	{
            		console.error('SearchManager => Error loading "text.bin"');
            		return this._searchCapability.reject();
            	}

            	console.info('SearchManager => Search file "text.bin" loaded');

            	// Return the decoded response
                return this._searchCapability.resolve(new Uint8Array(xhr.response));
            }, this);

            xhr.onerror = $.proxy(function(err) {
                return this._searchCapability.reject('Unable to load text.bin', url)
            }, this);

            xhr.send();
		},

		// Parsing functions
		_parseDocument: function(){
			this._pageIndexMap = [];

			_.each(this.Document.pages, function(page, pIndex){

				var start = 0;
				var text = '\n';
				var searchedText = '';

				var pageIndex = {
					index: pIndex,
					pageNumber: page.pageNumber,
					text: '',
					chunkMap: _.map(page.chunks, function(chunk, cIndex){
						text += chunk.text + '\n';

						var chunkLength = chunk.text.length;

						var cMap = {
							start: start,
							end: start + chunkLength
						};

						start += chunkLength + 1;

						return cMap;
					}, this)
				};

				pageIndex.text = text;

				this._pageIndexMap.push(pageIndex);

			}, this);

			console.log('SearchManager => Generated page mapping:', this._pageIndexMap);
		},

		_createSearchRegExp: function(query, options, flags){
			options || (options = {});

			flags || (flags = 'migd');

			var foldedQuery = this._filterAccents(query)
				// Remove white spaces
				.trim()
				// Escape special characters
				.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');

			if ( this._isRTL(foldedQuery) ) foldedQuery = foldedQuery.split('').reverse().join('');

			if ( options.exactMatch )
			{
				searchQuery = '(?<found>' + foldedQuery.replace(/[ ]+/g, ' ') + ')';
			}
			else
			{
				searchQuery = '(?<found>' + foldedQuery.replace(/[ ]+/g, '|') + ')';
			}

			if ( options.fullWords )
			{
				var surroundingChars = '\\\W+';
				searchQuery = surroundingChars + searchQuery + surroundingChars;
			}

			console.warn(searchQuery);

			var regExp = new RegExp(searchQuery, flags);

			return regExp;
		},

		_filterAccents: function(str){
			var self = this;
			return str.split('').map(function(e) {
				return self._accentFold(e)
			}).join('')
		},

		_accentFold: function(character){
			return character in AccentMap ? AccentMap[character] : character
		},

		_isRTL: function(s){
		    var ltrChars    = 'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF'+'\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF',
		        rtlChars    = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC',
		        rtlDirCheck = new RegExp('^[^'+ltrChars+']*['+rtlChars+']');

		    return rtlDirCheck.test(s);
		}
	});

});
