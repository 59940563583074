define('model-rect',[
	'underscore',
	'util'
], function (_, Util) {

	var Rect = function (x, y, w, h) {
		this.set(x, y, w, h);
	};

	_.extend(Rect.prototype, {
		// Properties
		_left: 0,
		_top: 0,
		_width: 0,
		_height: 0,

		// Methods
		set: function(x, y, w, h) // public
		{
			//console.debug('Rect:', x, y, w, h);

			this.left( _.isNaN(x) ? 0 : x );
			this.top( _.isNaN(y) ? 0 : y );
			this.width( _.isNaN(w) ? 0 : w );
			this.height( _.isNaN(h) ? 0 : h );
		},
		get: function()
		{
			// Return a new object representing the rectangle
			// Using left and top instead of x and y to allow the rect to be used for .css method
			return {
				left: this.left(),
				top: this.top(),
				width: this.width(),
				height: this.height()
			}
		},
		getCSS: function(unit){
			unit || (unit = 'px');
			return {
				left: this.left()+unit,
				top: this.top()+unit,
				width: this.width()+unit,
				height: this.height()+unit
			};
		},
		x: function(x) // public
		{
			if ( !_.isUndefined(x) ) this._left = Math.round(x);
			return this._left;
		},
		y: function(y) // public
		{
			if ( !_.isUndefined(y) ) this._top = Math.round(y);
			return this._top;
		},
		left: function(x) // public
		{
			return this.x(x);
		},
		right: function()
		{
			return this.left() + this.width();
		},
		top: function(y) // public
		{
			return this.y(y);
		},
		bottom: function()
		{
			return this.top() + this.height();
		},
		width: function(w) // public
		{
			if ( !_.isUndefined(w) ) this._width = Math.round(w);
			return this._width;
		},
		height: function(h) // public
		{
			if ( !_.isUndefined(h) ) this._height = Math.round(h);
			return this._height;
		},

		// Test if a Coord object is in the Rect coordinates using an optional tolerance to expand the collision area
		contains: function(coord, tolerance)
		{
			tolerance || (tolerance = 0);

			return ( ( coord.left() >= this.left() - tolerance && coord.left() <= this.left() + this.width() + tolerance ) && ( coord.top() >= this.top() - tolerance && coord.top() <= this.top() + this.height() + tolerance ) );
		},

		intersect: function(rect)
		{
			return !(rect.left() > this.right() ||
			         rect.right() < this.left() ||
			         rect.top() > this.bottom() ||
			         rect.bottom() < this.top());
		}
	});

	Rect.extend = Util.extend;

	return Rect;

});
