define('service-data-related',[
	'module',
	'underscore',
	'util',
	'service-data',
	'service-data-book',
	'service-config-book'
], function (module, _, Util, DataService, BookSrvc, BookConfigSrvc) {

	// Set up all inheritable **BaseConfig** properties and methods.
	return DataService.extend({}, {

		_name: module.id,

		load: function (options) {
			this.init();

			if ( !BookSrvc.feature('related.enabled') )
			{
				// No related, the promise is resolved as if the webservice returned no related
				this._promise.resolve();
				return this._promise;
			}

			return this.request( _.extend({
				url: !this.isFallback ? BookSrvc.feature('related.url') : BookSrvc.feature('related.fallback'),
				data:  _.extend({}, BookConfigSrvc.get()),
			}, options, this.getCredentialOptions && this.getCredentialOptions()) );
		},

		loadMore: function(options){
			this.init();

			if ( !BookSrvc.feature('related.enabled') )
			{
				// No related, the promise is resolved as if the webservice returned no related
				this._promise.resolve();
				return this._promise;
			}

			return this.request( _.extend({
				url: !this.isFallback ? BookSrvc.feature('related.url') : BookSrvc.feature('related.fallback'),
				data: _.extend({}, BookConfigSrvc.get(), {
					Page: this.page() + this.step(),
					Step: this.step()
				}),
			}, options, this.getCredentialOptions && this.getCredentialOptions()) );
		},

		page: function(){
			return parseInt( this.get('page') || 0, 0 );
		},
		step: function(){
			return parseInt( this.get('step') || 0, 0 );
		},
		num: function(){
			return this.get('books') ? this.get('books').length : 0;
		},
		count: function(){
			return this.page() + this.num();
		},
		total: function(){
			return parseInt( this.get('total') || 0, 0 );
		}
	});

});
