define('notification-warning',[
	'jquery',
	'underscore',
	'util',
	'notification'
], function ($, _, Util, Notification) {

	return Notification.extend(
		{
			className: 'notification type-warning'
		}
	);

});
