define('util-color',[
	'underscore'
], function (_){
	'use strict';
	return {
		isHex: function(s) {
	        return /^[0-9A-F]{6}$/i.test(s);
	    },
		decomposeHexColor: function(hex)
		{
			var color = {r: 0, g: 0, b: 0 };
			hex = String(hex).replace(/^(0x|#)/i,'');
			if ( hex.length == 3 ) hex = hex.substring(0,1)+hex.substring(0,1)+hex.substring(1,2)+hex.substring(1,2)+hex.substring(2,3)+hex.substring(2,3);
			if ( hex.length != 6 ) return color;
			color.r = parseInt( hex.substring(0,2), 16 );
			color.g = parseInt( hex.substring(2,4), 16 );
			color.b = parseInt( hex.substring(4,6), 16 );
			return color;
		},
		getRGBAColor: function(hex, alpha)
		{
			if ( _.isUndefined(alpha) ) alpha = 1;

			var c = {r: 0, g: 0, b: 0};

			if ( !_.isString(hex) )
			{
				c.b = hex & 255;
				c.g = ( hex >> 8 ) & 255;
				c.r = ( hex >> 16 ) & 255;
			}
			else
			{
				c = this.decomposeHexColor(hex);
			}

			return 'rgba('+c.r+','+c.g+','+c.b+','+alpha+')';
		}
	};
});
