define('manager-notification',[
	'jquery',
	'manager',
	'notification-info',
	'notification-warning',
	'notification-danger',
	'notification-success',
	'manager-viewport'
], function ($, Manager, InfoNotification, WarningNotification, DangerNotification, SuccessNotification, ViewportManager) {

	return Manager.extend({}, {

		add: function(notification){
			if ( this.IsDisabled ) return;

			console.info('NotificationManager => Add:', notification.msg, notification.duration);

			notification.appendTo( $('body') );

			var notif = notification.show();

			notif
				.promise()
				.done(
					this.clear.bind(this)
				);

			return notif
		},

		clear: function(){
		},

		// clearAll: function(){
		// 	$('body .notification').remove();
		// },

		info: function(msg, duration){
			return this.add( new InfoNotification({
				msg: msg,
				duration: duration || 0
			}) );
		},

		success: function(msg, duration){
			return this.add( new SuccessNotification({
				msg: msg,
				duration: duration || 0
			}) );
		},

		warning: function(msg, duration){
			return this.add( new WarningNotification({
				msg: msg,
				duration: duration || 0
			}) );
		},

		error: function(msg, duration){
			return this.add( new DangerNotification({
				msg: msg,
				duration: duration || 0
			}) );
		}
	});

});
