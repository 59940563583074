define('helpers/loader/error/view',[
	'jquery',
	'util',
	'ui-view',
	'manager-event',
	'./template',
	'tracker-ga4-calameo',
	'jquery-hammer'
], function ($, Util, UIView, EventManager, Template, TrackerCalameo) {

	return UIView.extend({
		className: 'loader-error',
		initialize: function(options){
			this.render();

			$('.error-overlay')
				.hammer()
				.on('tap', this.onClickHandler);

			$('.error-link')
				.hammer()
				.on('tap', $.proxy(this.onHelpHandler, this));

			var tracker = new TrackerCalameo();

			tracker.trackError(this.model.code, this.model.msg);
		},
		render: function(){

			this.$el.html( Template.error( this.model ) );

			$('body').addClass('clickable').addClass('mode-error').append( this.$el );

			return this;
		},
		onClickHandler: function(e){
			Util.url.openWindow('//www.calameo.com');
			return false;
		},
		onHelpHandler: function(e){
			Util.url.openWindow( this.model.help.url );
			return false;
		}
	});

});

