if ( !Calameo ) var Calameo = {};
if ( !Calameo.config ) Calameo.config = {};
Calameo.defaults = {
	base: {
		host: 'calameo.com',
		url: 'https://v.calameo.com/'
	},
	webservice: {
		url : [
			'https://d.calameo.com/pinwheel/viewer/book/get',
			'https://data.calameo.com/pinwheel/viewer/book/get'
		]
	},
	google: {
		analytics: {
			url: '//www.google-analytics.com/analytics.js',
			debug: false
		}
	},
	// For load new book
	platform: {
		url: 'https://www.calameo.com/'
	},
	cmp: {
		cookiebot: {
			cbid: '2372292c-680d-47b7-90f2-1af22359ed6d'
		}
	},
	debug: {
		url: [
			'https://d.calameo.com/pinwheel/viewer/debug/create',
			'https://data.calameo.com/pinwheel/viewer/debug/create'
		]
	}
};
var DEV = false;
define("config/prod", function(){});

