define('status',[
	'underscore',
	'constants',
	'manager-event',
	'manager-viewport',
	'service-config-loader',
	'service-data-book',
	'lockr'
], function (_, $C, EventManager, ViewportManager, LoaderConfigSrvc, BookSrvc, Lockr) {

	return {

		Events: {},

		AvailableViews: [],

		_displayedPages: [],

		_view: '',
		__view: '',
		view: function(view){
			if ( _.isUndefined(view) ) return this._view;

			if ( this._view == view && this._view != '' ) return this;

			this.__view = this._view;

			if ( [$C.VIEW_BOOK, $C.VIEW_SLIDE, $C.VIEW_SCROLL, $C.VIEW_SWIPE, $C.VIEW_INDEX, $C.VIEW_FALLBACK].indexOf(view) < 0 )
			{
				view = $C.VIEW_SLIDE;
			}

			if ( this.AvailableViews.indexOf(view) < 0 ) view = this.AvailableViews[0] || $C.VIEW_SLIDE;

			var totalPages = BookSrvc.pages();

			// No book view for <4 page documents
			view = totalPages <= 3 && view == $C.VIEW_BOOK ? $C.VIEW_SLIDE : view;

			// No scroll view for 1 page documents
			view = totalPages <= 1 ? $C.VIEW_SLIDE : view;

			// Restore normal state
			this.state( $C.STATE_NORMAL );

			this._view = view;

			console.warn('Status => View: '+ this._view +' [old='+ this.__view +']');

			// Adjust the current page
			// Correct the current page to avoid having a shift by 1 when loading an odd page first
			// if ( view == $C.VIEW_BOOK && this.page() ) this.page( this.page() );

			_.each(this.Viewers, function(v, k){
				ViewportManager.toggleClass('view-'+k, k == view);
				if ( k !== view ) v.deactivate();
			}, this);

			// Wait for all other views to be deactivated before activating the new one
			if ( !_.isUndefined(this.Viewers[view]) )
			{
				this.Viewers[view].activate();
			}
			else
			{
				console.error('Status => Unknown view:', view);
			}

			this.setDisplayedPages();

			var o = {};
			o[$C.ACTION_BOOK] = ( view !== $C.VIEW_BOOK && totalPages > 3 );
			o[$C.ACTION_SLIDE] = ( view !== $C.VIEW_SLIDE );
			o[$C.ACTION_SCROLL] = ( view !== $C.VIEW_SCROLL && totalPages > 1 );
			o[$C.ACTION_SWIPE] = ( view !== $C.VIEW_SWIPE );
			o[$C.ACTION_INDEX] = ( view !== $C.VIEW_INDEX );
			o[$C.ACTION_FALLBACK] = ( view !== $C.VIEW_FALLBACK );

			EventManager.trigger('skin:buttons', o);

			EventManager.trigger('context:global', {type: 'viewchanged', view: this._view, old: this.__view});

			return this;
		},
		previousView: function(){
			return this.__view || BookSrvc.get('document.view');
		},

		_mode: '',
		__mode: '',
		mode: function(mode){
			if ( _.isUndefined(mode) ) return this._mode;

			if ( this._mode == mode && this._mode != '' ) return this;

			if ( [$C.MODE_MINI, $C.MODE_NORMAL, $C.MODE_FULLSCREEN].indexOf(mode) < 0 )
			{
				console.error('Status => Incorrect mode:', mode);
				return this;
			};

			console.warn('Status => Mode: '+mode+' [old='+this._mode+']');

			_.each([$C.MODE_MINI, $C.MODE_NORMAL, $C.MODE_FULLSCREEN], function (m, i) {
				ViewportManager.toggleClass('mode-'+m, m == mode);
			});

			this.__mode = this._mode;

			this._mode = mode;

			EventManager.trigger('context:global', {type: 'modechanged', mode: this._mode, old: this.__mode});

			return this;
		},
		previousMode: function(){
			return this.__mode;
		},

		_state: '',
		__state: '',
		state: function(state){
			if ( _.isUndefined(state) ) return this._state;

			if (  this._state == state &&  this._state != ''  ) return this;

			if ( [$C.STATE_NORMAL, $C.STATE_ZOOM].indexOf(state) < 0 )
			{
				console.error('Status => Incorrect state:', state);
				return this;
			};

			console.warn('Status => State: '+state+' [old='+ this._state +']');

			_.each([$C.STATE_NORMAL, $C.STATE_ZOOM], function (s, i) {
				ViewportManager.toggleClass('state-'+s, s == state);
			});

			this.__state = this._state;

			this._state = state;

			EventManager.trigger('context:global', {type: 'statechanged', state: this._state, old: this.__state});

			return this;
		},
		previousState: function(){
			return this.__state;
		},

		_page: 0,
		__pages: [],
		page: function(page, target){
			if ( _.isUndefined(page) ) return this._page;

			if ( this.IsFlipping ) return;

			// Avoid strange page issue
			if ( isNaN(page) || !isFinite(page) ) page = 1;

			// Correct the current page to avoid having a shift by 1 when loading an odd page first
			// if ( this.view() == $C.VIEW_BOOK ) page -= page % 2;

			page = Math.max(1, Math.min(BookSrvc.pages(), page) );

			this.__pages.length ? this.__pages.unshift( this._page ) : this.__pages.push( page );

			if ( page == this._page ) return;

			console.warn('Status => Page: '+page+' [old='+this.previousPage()+']');

			this._page = page;

			this.setDisplayedPages( page );

			return this;
		},
		previousPage: function(){
			return this.__pages[0];
		},

		_volume: 0.25,
		__volume: 0,
		volume: function(volume){
			if ( _.isUndefined(volume) ) return this._volume;

			volume = Math.max(0, Math.min(100, volume) );

			if ( volume == this._volume ) return this;

			console.warn('Status => Volume: '+volume+' [old='+this._volume+']');

			this.__volume = this._volume;

			this._volume = volume;

			EventManager.trigger('context:audio', {type: 'volumechanged', target: this, volume: this._volume, old: this.__volume});

			var o = {};
			o[$C.ACTION_VOLUME_UP] = this._volume < 100;
			o[$C.ACTION_VOLUME_DOWN] = this._volume > 0;

			EventManager.trigger('skin:buttons', o);

			return this;
		},

		_getDisplayedPages: function(page)
		{
			page || (page = this.page());

			var pages = [];

			switch ( this.pageStep() )
			{
				case 2:
					var currentPageIndex;
					page = page % 2 === 0 ? page + 1 : page;
					var totalPages = BookSrvc.pages();
					if ( page - 1 >= 1 && page - 1 <= totalPages ) pages.push( page - 1 );
					if ( page >= 1 && page <= totalPages ) pages.push(page);
					break;

				default:
					pages = [page];
					break;
			}

			return pages;
		},
		getDisplayedPages: function(page)
		{
			if ( page ) return this._getDisplayedPages( page );
			return this._displayedPages;
		},
		setDisplayedPages: function(page)
		{
			this._displayedPages = this._getDisplayedPages( page );

			console.info('Status => Updating displayed pages:', this._displayedPages);
		},

		isOnPage: function(page)
		{
			page = parseInt( page, 0);
			if ( isNaN(page) ) return false;
			return this.getDisplayedPages().indexOf( page ) >= 0;
		},
		isOnLastPage: function(page)
		{
			return this.getDisplayedPages(page).indexOf( BookSrvc.pages() ) >= 0;
		},
		isOnFirstPage: function(page)
		{
			return this.getDisplayedPages(page).indexOf( 1 ) >= 0;
		},

		_zoomMode: $C.ZOOM_MODE_CURSOR,
		zoomMode: function(zoomMode)
		{
			if ( _.isUndefined(zoomMode) ) return this._zoomMode;

			this._zoomMode = zoomMode == $C.ZOOM_DRAG ? $C.ZOOM_DRAG : $C.ZOOM_CURSOR;

			console.warn('Status => Zoom mode: '+this._zoomMode+'');

			ViewportManager.toggleClass('zoom-mode-drag', this._zoomMode == $C.ZOOM_DRAG);

			// Switch the buttons
			var o = {};
			o[$C.ACTION_ZOOM_DRAG] = ( this._zoomMode != $C.ZOOM_DRAG );
			o[$C.ACTION_ZOOM_CURSOR] = ( this._zoomMode != $C.ZOOM_CURSOR );

			EventManager.trigger('skin:buttons', o);

			// Notify the change
			EventManager.trigger('context:zoom', {type: 'zoommodechanged', target: this, value: this._zoomMode});

			return this;
		},

		_isDragging: false,
		isDragging: function(isDragging)
		{
			if ( _.isUndefined(isDragging) ) return this._isDragging;

			this._isDragging = !!isDragging;
			console.log('Status => Dragging:', this._isDragging);
			ViewportManager.toggleClass('is-dragging', this._isDragging);
		},

		_isFlipping: false,
		isFlipping: function(isFlipping)
		{
			if ( _.isUndefined(isFlipping) ) return this._isFlipping;

			this._isFlipping = !!isFlipping;
			console.log('Status => Flipping:', this._isFlipping);
			// ViewportManager.toggleClass('is-flipping', this._isFlipping);
		},

		_isScrolling: false,
		isScrolling: function(isScrolling)
		{
			if ( _.isUndefined(isScrolling) ) return this._isScrolling;

			this._isScrolling = !!isScrolling;
			console.log('Status => Scrolling:', this._isScrolling);
		},

		_orientation: null,
		orientation: function(orientation)
		{
			if ( _.isUndefined(orientation) ) return this._orientation;

			this._orientation = orientation == $C.ORIENTATION_VERTICAL ? $C.ORIENTATION_VERTICAL : $C.ORIENTATION_HORIZONTAL;

			ViewportManager
				.toggleClass('orientation-portrait', this._orientation == $C.ORIENTATION_VERTICAL)
				.toggleClass('orientation-landscape', this._orientation == $C.ORIENTATION_HORIZONTAL);

			this.setDisplayedPages();

			console.warn('Status => Orientation: '+this._orientation+'');

			EventManager.trigger('context:resize', {type: 'orientation', orientation: this._orientation});
		},

		_debug: false,
		debug: function(debug)
		{
			if ( _.isUndefined(debug) ) return this._debug;

			this._debug = !!debug;
			console.log('Status => Debug:', this._debug);
		},

		_popupOpened: false,
		popupOpened: function(popupOpened)
		{
			if ( _.isUndefined(popupOpened) ) return this._popupOpened;

			this._popupOpened = !!popupOpened;
			console.log('Status => PopupOpened:', this._popupOpened);
		},

		Scale: 0,
		Zoom: 0,

		// PageStep: 1,
		_pageStep: false,
		pageStep: function(step)
		{
			if ( _.isUndefined(step) ) return this._pageStep;

			var step = parseInt(step, 0);
			var oldStep = this._pageStep;

			this._pageStep = step;
			console.warn('Status => PageStep:', this._pageStep);
			if ( step != oldStep ) this.setDisplayedPages();
		},

		isMini: function(){

			if ( ViewportManager.fullscreen() ) return false;

			var mode = LoaderConfigSrvc.get('mode');

			if ( mode == $C.LOADER_MODE_MINI ) return true;

			if ( !mode && ( ViewportManager.width() < $C.VIEWPORT_MIN_SIZE || ViewportManager.height() < $C.VIEWPORT_MIN_SIZE ) ) return true;

			return false;
		},

		Viewers: {},
		Layouts: {},

		BrandedViews: [],

		ViewerLayoutProperties: {}
	};


});


