define('util-flash',[
], function (
){
  'use strict';

	return {
 		hasFlash: function (){
			var mime = navigator.mimeTypes, has = false;

			if( navigator.plugins && typeof navigator.plugins['Shockwave Flash'] == 'object' ){
				has	= navigator.plugins['Shockwave Flash'].description && !(mime && mime['application/x-shockwave-flash'] && !mime['application/x-shockwave-flash'].enabledPlugin);
			}
			else {
				try {
					has	= !!(window.ActiveXObject && new ActiveXObject('ShockwaveFlash.ShockwaveFlash'));
				}
				catch(er){
					console.log('Flash not supported.');
				}
			}

			return has;
		},
		getFlashVersion : function () {
		  // ie
		  try {
		    try {
		      // avoid fp6 minor version lookup issues
		      // see: http://blog.deconcept.com/2006/01/11/getvariable-setvariable-crash-internet-explorer-flash-6/
		      var axo = new ActiveXObject('ShockwaveFlash.ShockwaveFlash.6');
		      try { axo.AllowScriptAccess = 'always'; }
		      catch(e) { return '6,0,0'; }
		    } catch(e) {}
		    return new ActiveXObject('ShockwaveFlash.ShockwaveFlash').GetVariable('$version').replace(/\D+/g, ',').match(/^,?(.+),?$/)[1];
		  // other browsers
		  } catch(e) {
		    try {
		      if(navigator.mimeTypes["application/x-shockwave-flash"].enabledPlugin){
		        return (navigator.plugins["Shockwave Flash 2.0"] || navigator.plugins["Shockwave Flash"]).description.replace(/\D+/g, ",").match(/^,?(.+),?$/)[1];
		      }
		    } catch(e) {}
		  }
		  return '0,0,0';
		}
	};
});
