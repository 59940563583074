define('cmp',[
	'jquery',
	'service-config-loader',
	'util'
], function ($, LoaderConfigSrvc, Util) {
	'use strict';

	var consentPromise = $.Deferred();

	return {
		load: function() {
			console.warn('CMP => Loading...');

			// Cookies consent
			if ( !Util.isEmbedded() )
			{
				// The Cookiebot script is in the page, we must be on Calaméo website
				var consentReady = function() {
					console.warn('CMP => [cookiebot] Consent ready', Cookiebot.consent);

					var consent = Cookiebot.consent;
					consentPromise.resolve(consent);
				};

				window.addEventListener('CookiebotOnConsentReady', consentReady);

				var cbid = Calameo.defaults.cmp && Calameo.defaults.cmp.cookiebot && Calameo.defaults.cmp.cookiebot.cbid;
				
				// <script src="https://consent.cookiebot.com/uc.js" data-framework="TCFv2.2" data-cbid="2372292c-680d-47b7-90f2-1af22359ed6d" type="text/javascript"></script>
				var script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = 'https://consent.cookiebot.com/uc.js';
				script.setAttribute('data-framework', 'TCFv2.2');
				script.setAttribute('data-cbid', cbid || '2372292c-680d-47b7-90f2-1af22359ed6d');
				script.onerror = function(err) {
					console.error('CMP => [cookiebot] Loading error', err);
					window.Cookiebot = {
						consent: {
							necessary: true,
							preferences: false,
							statistics: false,
							marketing: false
						}
					}
					consentReady();
				};
				script.onload = function() {
					console.log('CMP => [cookiebot] Loaded');
					console.log('CMP', Cookiebot);
				};
				document.head.appendChild(script);

				console.warn('CMP => [cookiebot] Loading');
			}
			else
			{
				// The Cookiebot script is not in the page, we must be in an embed
				// Embedded or read from outside, no cookie consent required
				var consent = {
					preferences: !LoaderConfigSrvc.get('disablehistory'),
					statistics: LoaderConfigSrvc.get('cookies'),
					marketing: LoaderConfigSrvc.get('cookies')
				};

				console.warn('CMP => [cookiebot] Not detected. Using delegated consent', consent);

				consentPromise.resolve(consent);
			}
		},
		promise: function() {
			return consentPromise;
		}
	};
});
