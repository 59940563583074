define('manager',['util'], function (Util) {

	var Manager = function () {
		throw new Error('Managers cannot be instanciated. Use static methods instead.');
	};

	Manager.extend = Util.extend;

	return Manager.extend({}, {
		IsDisabled: true,
		enable: function() // public
		{
			// Manager is now active
			this.IsDisabled = false;
		},
		disable: function() // public
		{
			// Manager is now inactive
			this.IsDisabled = true;
		}
	});

});
