define('service-config',[
	'underscore',
	'urljs',
	'util',
	'service'
], function (_, URL, Util, Service) {

	// Set up all inheritable **BaseConfig** properties and methods.
	return Service.extend({}, {

		defaults: {},

		load: function (options) {

			options || (options = {});

			this._promise = $.Deferred();

			var data = {};

			// Overwrite chain of config parameters (defaults first, then options, then URL query string)
			_.extend(data, this.defaults, this.getUrlParams(), options.parameters || {});

			// Lowercase all parameters and resolve them if they are functions
			var config = {};
			_.each(data, function (value, key, list) {
				console.log(key, value)
				// If the config parameter is not specified in default, ignore it
				if ( !_.isUndefined(this.defaults[key]) || !_.isUndefined(this.defaults[key.toLowerCase()]) )
				{
					var v = _.result(list, key);
					// Take care of boolean config values
					if ( _.isBoolean( this.defaults[key.toLowerCase()] ) ) v = v === true || v === 'true' || v === 1 || v === '1';

					// set param value
					config[key.toLowerCase()] = v;

					// remove any empty params
					if ( v === '' ) delete config[key.toLowerCase()];
				}
			}, this);

			// Set the config
			this.set(config);

			// Validate the config
			!this.validate() ? this._promise.reject() : this._promise.resolve();

			return this._promise;
		},

		has: function(key){
			return !_.isNull( this.get(key) );
		},

		get: function(key){
			if ( _.isString(key) ) key = key.toLowerCase();
			// Lowercase the path to avoid case mismatches
			return Service.get.apply(this, [key]);
		},

		validate: function(){
			return true;
		},

		getUrlParams: function () {
			var a = URL(window.location.href).query();
			var o = {};
			_.each(a, function(v){
				if ( !v[1] ) return;
				var value = '';
				try {
					value = decodeURIComponent(v[1]);
				} catch (err) {
					console.warn('Invalid URL parameter value for "%s"', v[0]);
				}
				o[v[0]] = value;
			});
			console.log(o);
			return o;
			//return URL(window.location.href).query(); //Util.url.toObject( window.location.search.substring(1), '&', '=' );
		}
	});

});
