define('service-data-debug',[
	'module',
	'underscore',
	'constants',
	'util',
	'service-data',
	'service-config-loader',
	'service-config-book',
	'manager-event'
], function (module, _, $C, Util, DataService, LoaderConfigSrvc, BookConfigSrvc, EventManager) {

	// Set up all inheritable **BaseConfig** properties and methods.
	return DataService.extend({}, {

		_name: module.id,

		_loader: $.ajax,

		_log: false,

		load: function (options) {
			options || (options = {});
			var urls = Calameo.defaults.debug.url.slice(0);
			var url = urls.shift();
			if ( this.isFallback ) url = urls.shift();
			return this.request( _.extend({
				type: 'POST',
				cache: false,
				xhrFields: {
    				withCredentials: true
    			},
				url: url
			}, options) );
		}
	});

});

