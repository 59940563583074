define('util-cookie',[
], function (
){
	'use strict';
	// TODO: maybe better to use mozilla helper
	// https://developer.mozilla.org/fr/docs/Web/API/document.cookie
	return {
		get : function ( name, value ) {
	        value = value || document.cookie;
	        var start = value.indexOf( ' ' + name + '=' );
	        if ( start == -1 ) {
	          start = value.indexOf( name + '=' );
	        }
	        if ( start == -1 ) {
	          value = null;
	        } else {
	          start = value.indexOf( '=', start ) + 1;
	          var end = value.indexOf( ';', start );
	          if ( end == -1 ) {
	            end = value.length;
	          }
	          value = unescape( value.substring( start, end ) );
	        }
			console.log('Cookies => Getting', name, value);
	        return value;
		},
		set: function ( name, value, path, domain, exdays, addon) {
			name = name || '';
			value = value || '';
			path = path || '/';
			domain = domain || window.location.hostname.split(".").slice(-2).join(".");
			var exdate=new Date();
			exdate.setDate(exdate.getDate() + exdays);
			var value=escape(value) + ";path=" + path + ";domain=" + domain + ((exdays===null) ? "" : "; expires="+exdate.toUTCString()) + ( addon || '' );
			console.log('Cookies => Setting', name, '=', value);
			document.cookie=name + "=" + value;
		},
		remove: function ( name, domain, path ) {
			if (!this.hasItem(name)) { return false; }
			domain = domain || window.location.hostname.split(".").slice(-2).join(".");
			console.log('Cookies => Removing', name, '=', value);
		   	document.cookie = encodeURIComponent(name) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (domain ? "; domain=" + domain : "") + (path ? "; path=" + path : "");
		   	return true;
		},
		hasItem: function (name) {
		   if (!name) { return false; }
			return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(name).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
		},
		keys: function () {
	    var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
	    for (var nIdx = 0; nIdx < aKeys.length; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
	    return aKeys;
	  }
	};
});
