define('helpers/loader/loading/view',[
	'jquery',
	'ui-view'
], function ($, UIView) {

	return UIView.extend({
		className: 'loader-loading loading rotating optimized',

		initialize: function(options){
			this.render();
		},

		render: function()
		{
			$('body').append( this.$el );
		}

	});

});
