/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define('proto-decoder-book-link',["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.BookLink = (function() {
    
        var BookLink = {};
    
        BookLink.Document = (function() {
    
            function Document(p) {
                this.pages = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }
    
            Document.prototype.version = 0;
            Document.prototype.pages = $util.emptyArray;
    
            Document.create = function create(properties) {
                return new Document(properties);
            };
    
            Document.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                    w.uint32(8).uint32(m.version);
                if (m.pages != null && m.pages.length) {
                    for (var i = 0; i < m.pages.length; ++i)
                        $root.BookLink.Document.Page.encode(m.pages[i], w.uint32(18).fork()).ldelim();
                }
                return w;
            };
    
            Document.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.BookLink.Document();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1: {
                            m.version = r.uint32();
                            break;
                        }
                    case 2: {
                            if (!(m.pages && m.pages.length))
                                m.pages = [];
                            m.pages.push($root.BookLink.Document.Page.decode(r, r.uint32()));
                            break;
                        }
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };
    
            Document.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/BookLink.Document";
            };
    
            Document.Page = (function() {
    
                function Page(p) {
                    this.links = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }
    
                Page.prototype.pageNumber = 0;
                Page.prototype.links = $util.emptyArray;
    
                Page.create = function create(properties) {
                    return new Page(properties);
                };
    
                Page.encode = function encode(m, w) {
                    if (!w)
                        w = $Writer.create();
                    if (m.pageNumber != null && Object.hasOwnProperty.call(m, "pageNumber"))
                        w.uint32(8).uint32(m.pageNumber);
                    if (m.links != null && m.links.length) {
                        for (var i = 0; i < m.links.length; ++i)
                            $root.BookLink.Document.Page.Link.encode(m.links[i], w.uint32(18).fork()).ldelim();
                    }
                    return w;
                };
    
                Page.decode = function decode(r, l) {
                    if (!(r instanceof $Reader))
                        r = $Reader.create(r);
                    var c = l === undefined ? r.len : r.pos + l, m = new $root.BookLink.Document.Page();
                    while (r.pos < c) {
                        var t = r.uint32();
                        switch (t >>> 3) {
                        case 1: {
                                m.pageNumber = r.uint32();
                                break;
                            }
                        case 2: {
                                if (!(m.links && m.links.length))
                                    m.links = [];
                                m.links.push($root.BookLink.Document.Page.Link.decode(r, r.uint32()));
                                break;
                            }
                        default:
                            r.skipType(t & 7);
                            break;
                        }
                    }
                    return m;
                };
    
                Page.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/BookLink.Document.Page";
                };
    
                Page.Link = (function() {
    
                    function Link(p) {
                        this.options = [];
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }
    
                    Link.prototype.type = 0;
                    Link.prototype.to = "";
                    Link.prototype.label = "";
                    Link.prototype.sx = 0;
                    Link.prototype.sy = 0;
                    Link.prototype.ex = 0;
                    Link.prototype.ey = 0;
                    Link.prototype.options = $util.emptyArray;
    
                    Link.create = function create(properties) {
                        return new Link(properties);
                    };
    
                    Link.encode = function encode(m, w) {
                        if (!w)
                            w = $Writer.create();
                        if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                            w.uint32(8).int32(m.type);
                        if (m.to != null && Object.hasOwnProperty.call(m, "to"))
                            w.uint32(18).string(m.to);
                        if (m.label != null && Object.hasOwnProperty.call(m, "label"))
                            w.uint32(26).string(m.label);
                        if (m.sx != null && Object.hasOwnProperty.call(m, "sx"))
                            w.uint32(32).int32(m.sx);
                        if (m.sy != null && Object.hasOwnProperty.call(m, "sy"))
                            w.uint32(40).int32(m.sy);
                        if (m.ex != null && Object.hasOwnProperty.call(m, "ex"))
                            w.uint32(48).int32(m.ex);
                        if (m.ey != null && Object.hasOwnProperty.call(m, "ey"))
                            w.uint32(56).int32(m.ey);
                        if (m.options != null && m.options.length) {
                            for (var i = 0; i < m.options.length; ++i)
                                $root.BookLink.Document.Page.Link.Option.encode(m.options[i], w.uint32(66).fork()).ldelim();
                        }
                        return w;
                    };
    
                    Link.decode = function decode(r, l) {
                        if (!(r instanceof $Reader))
                            r = $Reader.create(r);
                        var c = l === undefined ? r.len : r.pos + l, m = new $root.BookLink.Document.Page.Link();
                        while (r.pos < c) {
                            var t = r.uint32();
                            switch (t >>> 3) {
                            case 1: {
                                    m.type = r.int32();
                                    break;
                                }
                            case 2: {
                                    m.to = r.string();
                                    break;
                                }
                            case 3: {
                                    m.label = r.string();
                                    break;
                                }
                            case 4: {
                                    m.sx = r.int32();
                                    break;
                                }
                            case 5: {
                                    m.sy = r.int32();
                                    break;
                                }
                            case 6: {
                                    m.ex = r.int32();
                                    break;
                                }
                            case 7: {
                                    m.ey = r.int32();
                                    break;
                                }
                            case 8: {
                                    if (!(m.options && m.options.length))
                                        m.options = [];
                                    m.options.push($root.BookLink.Document.Page.Link.Option.decode(r, r.uint32()));
                                    break;
                                }
                            default:
                                r.skipType(t & 7);
                                break;
                            }
                        }
                        return m;
                    };
    
                    Link.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/BookLink.Document.Page.Link";
                    };
    
                    Link.LinkType = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "LINK_TYPE_UNKNOWN"] = 0;
                        values[valuesById[1] = "LINK_TYPE_EXTERNAL"] = 1;
                        values[valuesById[2] = "LINK_TYPE_INTERNAL"] = 2;
                        values[valuesById[3] = "LINK_TYPE_AUDIO"] = 3;
                        values[valuesById[4] = "LINK_TYPE_MEDIA"] = 4;
                        values[valuesById[5] = "LINK_TYPE_EMAIL"] = 5;
                        values[valuesById[6] = "LINK_TYPE_VIDEO"] = 6;
                        values[valuesById[8] = "LINK_TYPE_FOCUS"] = 8;
                        values[valuesById[9] = "LINK_TYPE_SHOPPING"] = 9;
                        values[valuesById[10] = "LINK_TYPE_INTEGRATION"] = 10;
                        return values;
                    })();
    
                    Link.Option = (function() {
    
                        function Option(p) {
                            if (p)
                                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                    if (p[ks[i]] != null)
                                        this[ks[i]] = p[ks[i]];
                        }
    
                        Option.prototype.key = "";
                        Option.prototype.value = "";
    
                        Option.create = function create(properties) {
                            return new Option(properties);
                        };
    
                        Option.encode = function encode(m, w) {
                            if (!w)
                                w = $Writer.create();
                            if (m.key != null && Object.hasOwnProperty.call(m, "key"))
                                w.uint32(10).string(m.key);
                            if (m.value != null && Object.hasOwnProperty.call(m, "value"))
                                w.uint32(18).string(m.value);
                            return w;
                        };
    
                        Option.decode = function decode(r, l) {
                            if (!(r instanceof $Reader))
                                r = $Reader.create(r);
                            var c = l === undefined ? r.len : r.pos + l, m = new $root.BookLink.Document.Page.Link.Option();
                            while (r.pos < c) {
                                var t = r.uint32();
                                switch (t >>> 3) {
                                case 1: {
                                        m.key = r.string();
                                        break;
                                    }
                                case 2: {
                                        m.value = r.string();
                                        break;
                                    }
                                default:
                                    r.skipType(t & 7);
                                    break;
                                }
                            }
                            return m;
                        };
    
                        Option.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/BookLink.Document.Page.Link.Option";
                        };
    
                        return Option;
                    })();
    
                    return Link;
                })();
    
                return Page;
            })();
    
            return Document;
        })();
    
        return BookLink;
    })();

    return $root;
});

