define('subscriber-login',[
	'jquery',
	'underscore',
	'status',
	'util',
	'ui-view',
	'service-config-book',
	'helpers/subscribers/template',
	'manager-event',
	'manager-viewport'
], function ($, _, $S, Util, UIView, BookConfigSrvc, Template, EventManager, ViewportManager) {

	return UIView.extend({

		_promise: null,

		className: 'loader-login',

		events: {
			'click'					: 'onClickHandler',
			'click .button-exit'	: 'onExitButtonHandler',
			'submit form'			: 'onSubmitHandler'
		},

		initialize: function(){

			this._promise = $.Deferred();

			this.render();

			EventManager.on('context:fullscreen', this.onFullscreenHandler, this);
		},

		onClickHandler: function(e){
			console.log(e);
			e.stopPropagation();
		},

		onFullscreenHandler: function(e, data){
			this.renderExitButton();
		},

		render: function () {
			$('body').find('.loader-login').remove();

			var backgroundColor = $('body').css('background-color');

			// Fill the element
			this.$el.html( Template.login({backgroundColor: backgroundColor}) );

			this.appendTo( 'body' );

			Util.delayBind(function(){
				this.addClass('fade-in');
				this.$('input[name="login"]').focus();
			}, this, 50);

			return this.renderExitButton();
		},

		renderExitButton: function(){
			this.$('.button-exit').toggle( ViewportManager.fullscreen() || window.location.protocol == 'data:' );
			return this;
		},

		onExitButtonHandler: function(e){
			this._promise.reject();
			if ( window.location.protocol == 'data:' )
			{
				window.close();
			}
			else
			{
				ViewportManager.exitFullscreen();
			}
		},

		promise: function(){
			return this._promise;
		},

		onSubmitHandler: function (e) {

			// Block the submit event
			e.preventDefault();
			e.stopPropagation();

			// Get the credentials
			var login = this.$('input[name="login"]').val();
			var passd = this.$('input[name="password"]').val();

			// If the credentials are all empty, do not make the call
			if ( _.isEmpty(login) && _.isEmpty(passd) ) return false;

			// Restart the process by resolving the promise
			this._promise.resolve(
				login,
				passd
			);

			// Destroy itself
			return this.destroy();
		},

		destroy: function(){
			EventManager.off('context:fullscreen', this.onFullscreenHandler, this);
			this.remove();
			return false;
		}

	});

});
