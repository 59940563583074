define('tracker-ga4-calameo',[
	'jquery',
	'underscore',
	'constants',
	'status',
	'util',
	'tracker-ga4',
	'service-data-book',
	'service-config-loader',
	'service-config-book'
], function ($, _, $C, $S, Util, Tracker, BookSrvc, LoaderConfigSrvc, BookConfigSrvc) {

	return Tracker.extend({

		id: 'ga4-calameo',

		isTrackingEnabled: function() {
			return !LoaderConfigSrvc.get('disabletracking') && !LoaderConfigSrvc.get('disablega') && !!BookSrvc.feature('tracking.ga4.calameo.enabled');
		},

		trackView: function(){
			var payload = $.extend(true, {}, this.getProperties());
			payload.page_location = '/read/' + payload.build_id + '/' + payload.publisher_id;
			this.trapEvent('page_view', payload);
		},

		trackMini: function(){
			var payload = $.extend(true, {}, this.getProperties());
			payload.page_location = '/mini/' + payload.build_id + '/' + payload.publisher_id;
			this.trapEvent('page_view', payload);
		},

		getName: function() {
			return 'Calameo'
		},

		getCode: function() {
			return BookSrvc.feature('tracking.ga4.calameo.code');
		},

		getProperties: function() {
			var properties = BookSrvc.feature('tracking.ga4.calameo.properties') || {};
			properties.tracker_source = LoaderConfigSrvc.get('trackersource');
			properties.ad_block = !!this.adBlockDetected;
			return properties;
		},

		getUserProperties: function() {
			return BookSrvc.feature('tracking.ga4.calameo.user_properties') || {};
		},

		getEventFilter: function() {
			return BookSrvc.feature('tracking.ga4.calameo.events') || null;
		},

		trackError: function(code, msg){
			var payload = {
				build_id: LoaderConfigSrvc.get('buildid') || '0000-000000',
				module: window.viewerModule || 'unknown',
				error_code: code
			};

			// Using "fail" as "error" does not seem to be received by GA4
			this.trapEvent('exception', payload);
		}
	});

});
