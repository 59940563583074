define('service-config-book',[
	'service-config',
	'util'
], function (Config, Util) {

	return Config.extend({}, {

		defaults: {
			bkcode: '',
			authid: '',
			login: '',
			password: '',
			subid: '',
			apikey: '',
			expires: '',
			ip: '',
			signature: ''
		},

		validate: function(){

			// Validate the book code
			var bookCode = this.get('bkcode');

			// Check existence and length
			if ( !bookCode || bookCode.length !== 21 ) return false;

			// Check format
			if ( !/^[0-9A-F]+$/i.test(bookCode) ) return false;

			return true;
		}

	});

});
