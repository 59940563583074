define('service',[
	'jquery',
	'underscore',
	'util'
], function ($, _, Util) {

	var Service = function () {
		this.initialize.apply(this, arguments);
	};

	Service.extend = Util.extend;

	return Service.extend({}, {
		_data: {},
		_promise: null,

		initialize: function(){
			throw new Error('Services cannot be instanciated. Use static methods instead.');
		},

		load: function(){ // Must return a promise
			this._promise = $.Deferred();

			this._promise.resolve( true, this.get() );

			return this._promise;
		},

		set: function(key, value)
		{
			if ( _.isUndefined(value) )
			{
				this._data = key;
			}
			else
			{
				var parts = key.split('.');
				var d = this._data;
				var len = parts.length;
				var k;
				for ( var i = 0 ; i < len ; i++ )
				{
					k = parts[i];
					if ( i + 1 < len )
					{
						if ( !_.isObject(d[k]) && !_.isArray(d[k]) ) d[k] = {};
						d = d[k];
					}
					else
					{
						d[k] = value;
					}
				}
			}

			return this;
		},

		// Get a key in the data
		get: function(key)
		{
			if ( _.isEmpty(key) || _.isUndefined(key) ) return this._data;
			var parts = key.split('.');
			var d = this._data;
			var len = parts.length;
			var k;
			for ( var i = 0 ; i < len ; i++ )
			{
				k = parts[i];
				if ( _.isUndefined(d[k]) ) return null;
				if ( !_.isObject(d[k]) && !_.isArray(d[k]) ) return d[k];
				d = d[k];
			}
			return d;
		}
	});

});
