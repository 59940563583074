define('notification-success',[
	'jquery',
	'underscore',
	'util',
	'notification'
], function ($, _, Util, Notification) {

	return Notification.extend(
		{
			className: 'notification type-success'
		}
	);

});
